/* tslint:disable:max-classes-per-file */
import {watch} from "@/store/store"
import Notifications from "@/managers/session/notifications"
import type {IStoreList} from "@/store/data/iStoreList"

export default abstract class List<T, U> {
	public get isReady(): boolean {
		return this.state.isReady
	}

	public get list(): T[] | null {
		return this.state.list
	}

	public get hasAny(): boolean {
		return this.isReady && this.list!.length > 0
	}

	public get hasMoreThanOne(): boolean {
		return this.isReady && this.list!.length > 1
	}

	protected abstract get dependent(): U | null
	protected abstract get state(): IStoreList<T>

	protected constructor(protected readonly itemName: string) {
		watch(
			() => this.dependent,
			dependent => {
				this.reset()
				if (dependent !== null)
					this.updateListWithDependent(dependent)
			}, {immediate: true})
	}

	public updateList(): Promise<T[]> {
		if (this.dependent === null)
			return Promise.resolve([])
		return this.updateListWithDependent(this.dependent)
	}

	protected updateListWithDependent(dependent: U): Promise<T[]> {
		return this.apiLoad(dependent)
			.then(list => {
				if (dependent === this.dependent)
					this.state.setList(list)
				return list
			}, reason => {
				Notifications.error(`Failed to update ${this.itemName} list`, reason)
				throw reason
			})
	}

	protected reset(): void {
		this.state.setList(null)
	}

	protected abstract apiLoad(dependent: U): Promise<T[]>
}

export {IStoreList}
