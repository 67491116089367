<template lang="pug">
	img(v-if="isRaw" :src="path" :width="width || size" :height="height")
	svg(
		v-else-if="!!icon"
		:style="{color: $vuetify.theme.themes.light[color] || color}"
		:width="width || fillSize || size"
		:height="height || fillSize || size"
		:stroke-width="strokeWidth"
		:viewBox="`0 0 ${fillSize || size} ${fillSize || size}`"
		:class="{'pa-0': fill || noOffset, shadow}"
		xmlns="http://www.w3.org/2000/svg"
	).poIcon
		circle(v-if="fill" cx="50%" cy="50%" r="50%" :fill="$vuetify.theme.themes.light[fill] || fill")
		use(:href="href" v-bind="fill && useAttrs")
</template>
<script lang="ts">
import {Component, Prop, Vue} from "@/mixins/combinations"

@Component({components: {}})
export default class PoIcon extends Vue {
	@Prop({required: true})
	public readonly icon!: string
	@Prop({required: false, default: "currentColor"})
	public readonly color!: string
	@Prop({required: false})
	public readonly fill!: string
	@Prop({required: false, default: 24})
	public readonly size!: number
	@Prop({required: false})
	public readonly fillSize!: number
	@Prop({required: false})
	public readonly width!: number
	@Prop({required: false})
	public readonly height!: number
	@Prop({required: false, default: 2})
	public readonly strokeWidth!: number
	@Prop({type: Boolean})
	public readonly shadow!: boolean
	@Prop({type: Boolean})
	public readonly noOffset!: boolean

	private get useAttrs(): object {
		return {
			x: (this.fillSize - this.size) / 2 || 0,
			y: (this.fillSize - this.size) / 2 || 0,
			width: this.size,
			height: this.size
		}
	}

	private get id(): string {
		return `${this.icon}_svg-ico`
	}

	private get path(): string {
		return `${require(`@/assets/icons/po-icons/${this.icon}.svg`)}`
	}

	private get href(): string {
		return `${this.path}#${this.id}`
	}

	private get isRaw(): boolean {
		return !!this.icon?.match(/^(animated\/|duotone\/|social\/).+/)
	}
}
</script>
<style lang="sass" scoped>
	.poIcon
		flex: 0 0 auto
		padding: 1px
		vertical-align: top
		&.shadow
			filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .61))
	img
		vertical-align: bottom
</style>
