// @ts-ignore
import lifecycle from "page-lifecycle/dist/lifecycle.native.mjs"

import {Vue} from "@/mixins/combinations"
import type FileReference from "@/managers/data/file/fileReference"
import {when} from "@/store/store"
import Configuration from "@/managers/system/configuration"
import type {PageState} from "@/types/page-lifecycle"

class Page {
	private data: {state: PageState}

	private preventUnloadSet = new Set<number>()
	private unloadHandler!: (event: BeforeUnloadEvent) => string | undefined

	public get isUnloaded(): boolean {
		return this.data.state === "frozen" || this.data.state === "terminated"
	}

	public get isVisible(): boolean {
		return !this.isUnloaded && this.data.state !== "hidden"
	}

	private get isUnloadPrevented(): boolean {
		return this.preventUnloadSet.size !== 0
	}

	constructor() {
		this.data = Vue.observable({state: lifecycle.state})

		lifecycle.addEventListener("statechange", (event: any) => this.data.state = event.newState)
		this.unloadHandler = event => this.handleUnload(event)
	}

	public callIfNotUnloaded(call: () => void): void {
		if (this.isUnloaded)
			return

		setTimeout(() => {
			if (!this.isUnloaded)
				call()
		}, Configuration.system.isUnloadedCheckDelay)
	}

	public preventUnloadWhileUploading(file: FileReference): void {
		if (!file.isUploading)
			throw new Error("Can't prevent unload on a file that's not uploading")

		const id = file.id

		this.preventUnload(id)
		when(() => !file.isUploading)
			.then(() => this.allowUnload(id))
	}

	public preventUnload(id: number): void {
		if (!this.isUnloadPrevented)
			window.addEventListener("beforeunload", this.handleUnload)
		this.preventUnloadSet.add(id)
	}

	public allowUnload(id: number): void {
		this.preventUnloadSet.delete(id)

		if (!this.isUnloadPrevented)
			window.removeEventListener("beforeunload", this.handleUnload)
	}

	private handleUnload(event: BeforeUnloadEvent): string | undefined {
		event.preventDefault()
		const message = "You have files uploading"
		event.returnValue = message
		return message
	}
}

export default new Page()
