import type {IPublicServiceConnection} from "@chaosinsight/postoffice-portalclient"
import {ServiceConnectionType} from "@chaosinsight/postoffice-portalclient"
import PublicUser from "@/managers/data/user/publicUser"
import ServiceConnection from "@/managers/data/serviceConnection/serviceConnection"

export default class PublicServiceConnection extends ServiceConnection {
	public user: PublicUser

	constructor(id: string, typeId: ServiceConnectionType, serviceId: string, mustRenew: boolean, createdOn: Date, modifiedOn: Date, user: PublicUser) {
		super(id, typeId, serviceId, mustRenew, createdOn, modifiedOn)
		this.user = user
	}

	public static fromApi(data: IPublicServiceConnection): PublicServiceConnection {
		return new PublicServiceConnection(
			data.Id,
			data.TypeId,
			data.ServiceId,
			data.MustRenew,
			new Date(data.CreatedOn),
			new Date(data.ModifiedOn),
			PublicUser.fromApi(data.User))
	}
}

export {ServiceConnectionType}
