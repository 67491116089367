import State from "@/store/system/install"
import {InstallState} from "@/store/data/installState"
import Notifications from "@/managers/session/notifications"
import Configuration from "@/managers/system/configuration"
import Tracking from "@/managers/session/logging/tracking"

class Install {
	public readonly firstVersion = "1.0.0"
	public readonly version = Configuration.system.version
	private readonly versionParser = /(^\d+)\.(\d+)\.(\d+)(?:-.+)?$/
	private readyUpdate: ServiceWorkerRegistration | null = null

	public get versionNoReleaseInfo(): string {
		return this.version.split("-")[0]
	}

	public get isUpdateAvailable(): boolean {
		return State.current === InstallState.updateAvailable
	}

	public get isUpdateReady(): boolean {
		return State.current === InstallState.updateIsReady
	}

	public get newVersion(): string | null {
		const newVersion = State.newVersion

		return newVersion !== null ? `${newVersion[0].toString(10)}.${newVersion[1].toString(10)}.${newVersion[2].toString(10)}` : null
	}

	public get isImportantUpdateReady(): boolean {
		const currentVersion = this.parseVersion(this.version)
		const newVersion = State.newVersion
		return this.isUpdateReady && newVersion !== null
			&& (newVersion[0] > currentVersion[0]
				|| newVersion[1] > currentVersion[1]
				|| newVersion[2] > currentVersion[2] + Configuration.system.updateThreshold)
	}

	public get hasRequestedUpdateDialog(): boolean {
		return State.hasRequestedUpdateDialog
	}
	public set hasRequestedUpdateDialog(value: boolean) {
		State.setHasRequestedUpdateDialog(value)
	}

	public get canRequestUpdateDialog(): boolean {
		return this.isUpdateReady && !this.hasRequestedUpdateDialog
	}

	public get isUpdating(): boolean {
		return State.current === InstallState.isUpdating
	}

	public get isOffline(): boolean {
		return State.isOffline
	}

	public set isOffline(value: boolean) {
		State.setIsOffline(value)
	}

	constructor() {
		try {
			this.isOffline = !window.navigator.onLine
			window.addEventListener("offline", () => this.isOffline = true)
			window.addEventListener("online", () => this.isOffline = false)

			if (!window.navigator.serviceWorker) {
				Tracking.event("ServiceWorker", "Not available", undefined, true)
				Notifications.debug("Service worker not available")
				return
			}

			let isRefreshing = false
			window.navigator.serviceWorker.addEventListener("controllerchange", () => {
				if (isRefreshing)
					return
				isRefreshing = true
				window.location.reload()
			})

			window.navigator.serviceWorker.addEventListener("message", event => {
				if (!event.data || !event.data.hasOwnProperty("type"))
					return

				switch (event.data.type) {
					case "version":
						try {
							State.setNewVersion(this.parseVersion(event.data.version))
							Notifications.debug("New version ready: " + event.data.version)
							Tracking.event("Version", "ready", event.data.version, true)
						} catch (error: any) {
							Notifications.warning("Failed to set new version", error)
						}
						break
				}
			})
		} catch (error: any) {
			Notifications.warning("Failed to listen to serviceWorker: " + error.message)
		}
		Tracking.event("Version", "current", this.version, true)
	}

	public updateIsAvailable(): void {
		State.setCurrent(InstallState.updateAvailable)
	}

	public updateIsReady(registration: ServiceWorkerRegistration): void {
		this.readyUpdate = registration
		if (this.readyUpdate.waiting !== null)
			this.readyUpdate.waiting.postMessage({type: "getVersion"})
		else
			Notifications.warning("Worker waiting for update is null")

		State.setCurrent(InstallState.updateIsReady)
	}

	public update(): void {
		if (!this.isUpdateReady || this.readyUpdate === null)
			throw new Error("Update is not ready")

		if (this.readyUpdate.waiting == null)
			throw new Error("No service worker waiting")

		State.setCurrent(InstallState.isUpdating)
		this.readyUpdate.waiting.postMessage({type: "skipWaiting"})
	}

	private parseVersion(value: string): [number, number, number] {
		const result = this.versionParser.exec(value)

		if (result === null)
			throw new Error(`Failed to parse version: ${value}`)

		return result.slice(1, 4).map(n => parseInt(n, 10)) as [number, number, number]
	}
}

export default new Install()
