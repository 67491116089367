import Component from "vue-class-component"
import type { RawLocation, Route } from "vue-router"

Component.registerHooks([
	"beforeRouteEnter",
	"beforeRouteLeave",
	"beforeRouteUpdate"
])

declare module "vue/types/vue" {
	// tslint:disable-next-line:interface-name
	interface Vue {
		beforeRouteEnter?(
			to: Route,
			from: Route,
			next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
		): void

		beforeRouteLeave?(
			to: Route,
			from: Route,
			next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
		): void

		beforeRouteUpdate?(
			to: Route,
			from: Route,
			next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
		): void
	}
}
