import Location from "@/managers/data/location/location"
import Portal from "@/managers/portal"
import PublicUser from "@/managers/data/user/publicUser"
import {Vue} from "@/mixins/combinations"
import {when} from "@/store/store"
import Authentication from "@/managers/authentication/authentication"
import {addGlobalEventListener, globalEvents} from "@/managers/session/globalEvents"
import type User from "@/managers/data/user/user"
import type DealConfiguration from "@/managers/data/organization/dealConfiguration"

class Locations {
	private readonly state: {main: Location | null, count: number | null}

	public get main(): Location | null {
		return this.state.main
	}

	public get isReady(): boolean {
		return Authentication.isAuthenticated
	}

	public get hasMain(): boolean {
		return this.main !== null
	}

	public get hasUpdatedCount(): boolean {
		return this.state.count !== null
	}

	public get hasAny(): boolean {
		return this.hasUpdatedCount ? this.state.count! > 0 : false
	}

	public get hasMultiple(): boolean {
		return this.hasUpdatedCount ? this.state.count! > 1 : false
	}

	public get count(): number {
		return this.state.count!
	}

	private get mainOrError(): Location {
		const main = this.main
		if (main !== null)
			return main
		throw new Error("Main location not set")
	}

	constructor() {
		this.state = Vue.observable({main: null, count: null})

		when(() => this.isReady)
			.then(() => this.updateCount())
		addGlobalEventListener(globalEvents.userChanged, user => this.updateUser(user))
	}

	public async updateCount(): Promise<void> {
		const rawLocations = await this.getLocations(0, 2)

		this.state.count = rawLocations.length
	}

	public modifyLogo(file: File): Promise<void> {
		return this.modifyMainAfterCall(
			id => Portal.call.locations.logosPost(id, file).response,
			response => ({logoUrl: response.Url})
		)
	}

	public async modifyTimeZone(timeZone: string): Promise<void> {
		return this.modify({timeZone})
	}

	public async modifyIsLikeCommentOnOrderEnabled(isLikeCommentOnOrderEnabled: boolean): Promise<void> {
		return this.modify({isLikeCommentOnOrderEnabled})
	}

	public async modifyIsDealPrioritizingMessaging(isPrioritizingMessaging: boolean | null): Promise<void> {
		return this.modify({isPrioritizingMessaging})
	}

	public async modifyDealConfiguration(dealConfiguration: DealConfiguration): Promise<void> {
		return this.modifyPatch({dealConfiguration})
	}

	public async updateUsers(): Promise<void> {
		return this.modifyMainAfterCall(
			id => Portal.call.locations.usersGet(id).response
				.then(response => response.map(user => PublicUser.fromApi(user))),
			response => ({users: response, userCount: response.length})
		)
	}

	public async removeUser(userId: string): Promise<void> {
		return this.modifyMainAfterCall(
			id => Portal.call.locations.usersDelete(id, userId).response,
			response => {
				let users = this.main!.users

				if (users === null)
					return {}

				users = users.filter(u => u.id !== userId)
				return {users, userCount: users.length}
			}
		)
	}

	public getLocations(index: number, size: number, query?: string): Promise<Location[]> {
		return Portal.call.locations.get(index, size, query).response
			.then(response => response.map(Location.fromApi))
	}

	public async getLocationFromId(id: number): Promise<Location> {
		if (this.hasMain && this.main!.id === id)
			return this.main!

		const locations = await Portal.call.locations.get().response
			.then(response => response.map(Location.fromApi))

		this.state.count = locations.length

		for (const location of locations)
			if (location.id === id)
				return location

		throw new Error(`Location with id: ${id} not found`)
	}

	public async setMainFromKey(key: string): Promise<Location> {
		if (this.main?.key === key)
			return this.main

		const location = Location.fromApi(await Portal.call.locations.getByKey(key).response)

		this.state.main = location
		return location
	}

	public clearMain(): void {
		this.state.main = null
	}

	public async modifyMainAfterCall<T>(call: (id: number) => Promise<T>, change: (response: T) => Partial<Location>): Promise<void> {
		const main = this.mainOrError

		return call(main.id)
			.then(
				response => {
					if (main.id !== this.main?.id)
						return

					const changes = change(response) as any

					for (const key in changes)
						if (changes[key] !== undefined)
							(main as any)[key] = changes[key]
				}
			)
	}

	protected apiDelete(dependent: boolean, id: Location["id"]): Promise<void> {
		return Portal.call.locations.delete(id).response
	}

	protected apiLoad(dependent: true): Promise<Location[]> {
		return Portal.call.locations.get()
			.response
			.then(locations => locations.map(Location.fromApi))
			.then(locations => locations.sort((l1, l2) => l1.name.localeCompare(l2.name)))
	}

	private updateUser(updatedUser: User): void {
		if (this.main === null || this.main.users === null)
			return
		for (const user of this.main.users) {
			if (user.id !== user.id)
				continue

			user.updaterFromUser(updatedUser)
			return
		}
	}

	private async modify(data: Partial<Location>): Promise<void> {
		const location = {...this.mainOrError, ... data}

		return this.modifyMainAfterCall(
			id => Portal.call.locations.put(
				id, location.name, location.isSharingInsightsWithOrganization, location.timeZone, location.language, location.userDefinedId,
				location.isPrioritizingMessaging,
				{
					IsDealsEnabled: location.isDealsEnabled,
					IsLiveDealsEnabled: location.isLiveDealsEnabled,
					IsLikeCommentOnOrderEnabled: location.isLikeCommentOnOrderEnabled,
					IsPickupReminderEnabled: location.isPickupReminderEnabled
				}
			).response,
			response => location
		)
	}

	private async modifyPatch(data: Partial<Location>): Promise<void> {
		const location = {...this.mainOrError, ... data}
		return this.modifyMainAfterCall(
			id => Portal.call.locations.patch(
				id, location.dealConfiguration.toApi(),
			).response,
			response => location
		)
	}
}

export {Location}
export default new Locations()
