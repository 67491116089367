import type {IPublicUser} from "@chaosinsight/postoffice-portalclient"
import User from "@/managers/data/user/user"

export default class PublicUser extends User {
	public createdOn: Date
	public lastSeenOn: Date
	public memberOfLocationsCount: number

	constructor(id: string, emailAddress: string, name: string, profilePictureUrl: string | null, createdOn: Date, lastSeenOn: Date, memberOfLocationsCount: number) {
		super(id, emailAddress, name, profilePictureUrl)
		this.createdOn = createdOn
		this.lastSeenOn = lastSeenOn
		this.memberOfLocationsCount = memberOfLocationsCount
	}

	public static fromApi(data: IPublicUser): PublicUser {
		return new PublicUser(
			data.Id,
			data.Email,
			data.Name,
			data.ProfilePictureUrl,
			new Date(data.CreatedOn),
			new Date(data.LastSeenOn),
			data.MemberOfLocationsCount)
	}
}
