import {IService, ServiceConnectionType} from "@chaosinsight/postoffice-portalclient"
import PublicServiceConnection from "@/managers/data/serviceConnection/publicServiceConnection"
import Service from "@/managers/data/serviceConnection/service"

export default class DetailedService extends Service {
	public locationId: number
	public connections: PublicServiceConnection[]

	public get hasActiveConnections(): boolean {
		return this.connections.some(c => !c.mustRenew)
	}

	constructor(externalId: string, locationId: number, name: string | null, type: ServiceConnectionType, connections: PublicServiceConnection[]) {
		super(externalId, name, type)
		this.locationId = locationId
		this.connections = connections
	}

	public static fromApi(data: IService): DetailedService {
		return new DetailedService(
			data.ExternalId,
			data.LocationId,
			data.Name,
			data.Type,
			data.Connections.map(PublicServiceConnection.fromApi)
		)
	}
}

export {ServiceConnectionType, PublicServiceConnection}
