import Notifications from "@/managers/session/notifications"

class FallbackStorage {
	public readonly type: "localStorage" | "sessionStorage"
	private storage: Storage | null = null
	private fallback: Map<string, string> | null = null

	public get isAvailable(): boolean {
		if (this.storage !== null)
			return true
		if (this.fallback !== null)
			return false

		if (FallbackStorage.isStorageAvailable(this.type)) {
			this.storage = window[this.type]
			return true
		}

		this.fallback = new Map<string, string>()
		Notifications.warning("Storage of type " + this.type + " is not available")
		return false
	}

	constructor(type: "localStorage" | "sessionStorage") {
		this.type = type
	}

	public getItem(key: string): string | null {
		return this.isAvailable
			? this.storage!.getItem(key)
			: this.fallback!.get(key) ?? null
	}

	public setItem(key: string, value: string): void {
		if (this.isAvailable)
			this.storage!.setItem(key, value)
		else
			this.fallback!.set(key, value)
	}

	public removeItem(key: string): void {
		if (this.isAvailable)
			this.storage!.removeItem(key)
		else
			this.fallback!.delete(key)
	}

	private static isStorageAvailable(type: "localStorage" | "sessionStorage"): boolean {
		let storage
		const key = "__storage_test__"
		try {
			storage = window[type]
			storage.setItem(key, key)
			storage.removeItem(key)
			return true
		} catch (e) {
			return false
		}
	}
}

export const localStorage = new FallbackStorage("localStorage")
export const sessionStorage = new FallbackStorage("sessionStorage")
