import ErrorStackParser, {StackFrame} from "error-stack-parser"

const abortMessages = [
	"Failed to fetch",
	"cancelled",
	"annulleret",
	"NetworkError when attempting to fetch resource."
]

export function getErrorLocation(error?: Error): string {
	if (!error)
		return "No error"

	try {
		const frames = ErrorStackParser.parse(error)

		if (frames.length === 0)
			return "Unknown"

		return (frames[0].functionName || (frames[0].fileName + ":" + frames[0].lineNumber))
	} catch (error) {
		return "Failed to parse stack"
	}
}

export function getErrorStack(error?: Error): StackFrame[] {
	if (!error)
		return []

	try {
		const frames = ErrorStackParser.parse(error)

		if (frames.length === 0)
			return []

		return frames

	} catch (error) {
		return []
	}
}

export function isPotentialAborted(error: Error): boolean {
	return (error instanceof TypeError) && abortMessages.includes(error.message)
}

export {StackFrame}
