import type {IDealText, IDealTextAndPlaceholders, IDealTextWithLanguage, IDefaultDealTextConfiguration, ILocationDealTextConfiguration, IOrganizationDealTextConfiguration} from "@chaosinsight/postoffice-portalclient"

const dealTextLiteralKeys: Array<keyof DealTexts> = ["alcoholFooter", ]

export default class DealTexts {
	private static excludedProperties: Array<keyof DealTexts> = ["language"]
	public readonly language!: string

	public alcoholFooter!: string[]
	public outOfStockHeader!: string[]
	public expiredHeader!: string[]
	public pleaseTryAgain!: string[]
	public pickupReminder!: string[]
	public pickupReminderLastDay!: string[]

	public footer!: string[]
	public variantFooter!: string[]
	public confirmation!: string[]
	public confirmationWithLink!: string[]
	public expired!: string[]
	public outOfStock!: string[]
	public exceedsMaxAllowedQuantity!: string[]
	public exceedsStock!: string[]

	public liveFooter!: string[]
	public robotDisconnectedHeader!: string[]
	public orderCheckout!: string[]
	public liveConfirmation!: string[]
	public liveConfirmationWithLink!: string[]
	public robotConnected!: string[]
	public productSoldOut!: string[]

	private constructor(language: string) {
		this.language = language
	}

	public fromApi(data: IOrganizationDealTextConfiguration | IDefaultDealTextConfiguration | ILocationDealTextConfiguration): this {
		this.alcoholFooter = this.getValues(data.Common.Texts.AlcoholFooter)
		this.outOfStockHeader = this.getValues(data.Common.Texts.OutOfStockHeader)
		this.expiredHeader = this.getValues(data.Common.Texts.ExpiredHeader)
		this.pleaseTryAgain = this.getValues(data.Common.Texts.PleaseTryAgain)
		this.pickupReminder = this.getValues(data.Common.Texts.PickupReminder)
		this.pickupReminderLastDay = this.getValues(data.Common.Texts.PickupReminderLastDay)

		this.footer = this.getValues(data.Regular.Texts.Footer)
		this.variantFooter = this.getValues(data.Regular.Texts.VariantFooter)
		this.confirmation = this.getValues(data.Regular.Texts.Confirmation)
		this.confirmationWithLink = this.getValues(data.Regular.Texts.ConfirmationWithLink)
		this.expired = this.getValues(data.Regular.Texts.Expired)
		this.outOfStock = this.getValues(data.Regular.Texts.OutOfStock)
		this.exceedsMaxAllowedQuantity = this.getValues(data.Regular.Texts.ExceedsMaxAllowedQuantity)
		this.exceedsStock = this.getValues(data.Regular.Texts.ExceedsStock)

		this.liveFooter = this.getValues(data.Live.Texts.LiveFooter)
		this.robotDisconnectedHeader = this.getValues(data.Live.Texts.RobotDisconnectedHeader)
		this.orderCheckout = this.getValues(data.Live.Texts.OrderCheckout)
		this.liveConfirmation = this.getValues(data.Live.Texts.Confirmation)
		this.liveConfirmationWithLink = this.getValues(data.Live.Texts.ConfirmationWithLink)
		this.robotConnected = this.getValues(data.Live.Texts.RobotConnected)
		this.productSoldOut = this.getValues(data.Live.Texts.ProductSoldOut)

		return this
	}

	public clone(): DealTexts {
		const clone = new DealTexts(this.language) as any

		for (const [key, value] of Object.entries(this)) {
			if (DealTexts.excludedProperties.includes(key as keyof DealTexts))
				continue
			clone[key as keyof DealTexts] = [...value as string[]]
		}

		return clone
	}

	public cloneAndReplace(other: DealTexts): DealTexts {
		const clone = new DealTexts(this.language) as any

		for (const [key, value] of Object.entries(this)) {
			if (DealTexts.excludedProperties.includes(key as keyof DealTexts))
				continue

			const otherValue = other[key as keyof DealTexts] as string[]

			clone[key as keyof DealTexts] = otherValue.length > 0 ? [...otherValue] : [...value as string[]]
		}

		return clone
	}

	private getValues(data: IDealText[] | IDealTextWithLanguage[] | IDealTextAndPlaceholders): string[] {
		const rawValues = "Placeholders" in data ? (data as IDealTextAndPlaceholders).Texts : data as (IDealText[] | IDealTextWithLanguage[])

		return rawValues
			.filter(t => !("Language" in t) || t.Language === this.language)
			.map(t => t.Text)
	}

	public static fromApi(data: IDefaultDealTextConfiguration | IOrganizationDealTextConfiguration | ILocationDealTextConfiguration, language: string): DealTexts {
		return new this(language)
			.fromApi(data)
	}

	public static empty(language: string): DealTexts {
		const texts = new DealTexts(language)

		for (const [key, value] of Object.entries(texts)) {
			if (DealTexts.excludedProperties.includes(key as keyof DealTexts))
				continue
			;(texts as any)[key] = []
		}

		return texts
	}

	public static eachLanguageFromApi(data: IDefaultDealTextConfiguration | IOrganizationDealTextConfiguration | ILocationDealTextConfiguration): Map<string, DealTexts> {
		const languages = this.getLanguages(data)
		return new Map<string, DealTexts>(languages.map(language => [language, this.fromApi(data, language)]))
	}

	public static getLanguages(data: IDefaultDealTextConfiguration | IOrganizationDealTextConfiguration | ILocationDealTextConfiguration): string[] {
		const languages = new Set<string>()
		const isDefault = "Placeholders" in data.Common.Texts.AlcoholFooter

		;(["Common", "Regular", "Live"] as Array<keyof (typeof data)>).forEach(key => {
			Object.values(data[key].Texts).forEach(
				textArray => {
					if (isDefault)
						textArray = textArray.Texts
					;(textArray as IDealTextWithLanguage[]).forEach(text => languages.add(text.Language))
				}
			)
		})

		return Array.from(languages.keys())
	}
}

export type DealTextKeys = Exclude<keyof DealTexts, "toApi" | "fromApi" | "clone" | "cloneAndReplace" | "language">
