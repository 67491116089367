import Vue from "vue"
import Router, { RawLocation, Route } from "vue-router"
import routerGuardAuthentication from "./routerGuardAuthentication"
import routerGuardStatus from "@/plugins/routerGuardStatus"
import routerGuardOrganization from "@/plugins/routerGuardOrganization"
import routerGuardLocation from "@/plugins/routerGuardLocation"
import Navigation from "@/managers/session/navigation"
import Preferences from "@/managers/system/preferences"
import CalendarType from "@/managers/data/preferences/calendarType"

const Login = () => import(/* webpackChunkName: "login" */ "@/views/login.vue")
const ResetPassword = () => import(/* webpackChunkName: "login" */ "@/views/resetPassword.vue")

const NotFoundView = () => import(/* webpackChunkName: "common" */ "@/views/notFound.vue")
const TermsView = () => import(/* webpackChunkName: "common" */ "@/views/terms.vue")
const PrivacyView = () => import(/* webpackChunkName: "common" */ "@/views/privacy.vue")
const UserAccountView = () => import(/* webpackChunkName: "common" */ "@/views/userAccount.vue")

const JoinOrganization = () => import(/* webpackChunkName: "invitation" */ "@/views/invitation/joinOrganization/joinOrganization.vue")
const SubscribeToOrganization = () => import(/* webpackChunkName: "invitation" */ "@/views/invitation/subscribeToOrganization/subscribeToOrganization.vue")
const JoinLocation = () => import(/* webpackChunkName: "invitation" */ "@/views/invitation/joinLocation/joinLocation.vue")
const CreateLocation = () => import(/* webpackChunkName: "invitation" */ "@/views/invitation/createLocation/createLocation.vue")

const SignUp = () => import(/* webpackChunkName: "login" */ "@/views/signUp/signUp.vue")
const SignUpUserSelection = () => import(/* webpackChunkName: "login" */ "@/views/signUp/userSelection.vue")
const SignUpCreateOrganization = () => import(/* webpackChunkName: "login" */ "@/views/signUp/createOrganization.vue")
const SignUpSelectModules = () => import(/* webpackChunkName: "login" */ "@/views/signUp/selectModules.vue")
const SignUpConnectPlatforms = () => import(/* webpackChunkName: "login" */ "@/views/signUp/connectPlatforms.vue")

const User = () => import(/* webpackChunkName: "user" */ "@/views/user/user.vue")
const UserLocationSelection = () => import(/* webpackChunkName: "user" */ "@/views/user/locationSelection/locationSelection.vue")
const UserAccount = () => import(/* webpackChunkName: "user" */ "@/views/user/account/account.vue")
const UserAccountDealsReplyTexts = () => import(/* webpackChunkName: "user" */ "@/views/user/account/locationDealsReplyTexts.vue")
const UserAccountDealsPostTexts = () => import(/* webpackChunkName: "user" */ "@/views/user/account/locationDealsPostTexts.vue")
const UserCalendar = () => import(/* webpackChunkName: "user" */ "@/views/user/calendar/calendar.vue")
const UserCalendarGrid = () => import(/* webpackChunkName: "user" */ "@/views/user/calendarGrid/calendarGrid.vue")
const UserPostEditor = () => import(/* webpackChunkName: "user" */ "@/views/user/postEditor/postEditor.vue")
const UserContent = () => import(/* webpackChunkName: "user" */ "@/views/user/content/content.vue")
const UserDeals = () => import(/* webpackChunkName: "user" */ "@/views/user/deals/deals.vue")
const UserDealsOrders = () => import(/* webpackChunkName: "user" */ "@/views/user/orders/orders.vue")
const UserInsights = () => import(/* webpackChunkName: "user" */ "@/views/user/insights/insights.vue")

const Admin = () => import(/* webpackChunkName: "admin" */ "@/views/admin/admin.vue")
const AdminOrganizationSelection = () => import(/* webpackChunkName: "admin" */ "@/views/admin/organizationSelection/organizationSelection.vue")
const AdminAccount = () => import(/* webpackChunkName: "admin" */ "@/views/admin/account/account.vue")
const AdminCalendar = () => import(/* webpackChunkName: "admin" */ "@/views/admin/calendar/calendar.vue")
const AdminCalendarGrid = () => import(/* webpackChunkName: "admin" */ "@/views/admin/calendarGrid/calendarGrid.vue")
const AdminScheduledTemplateEditor = () => import(/* webpackChunkName: "admin" */ "@/views/admin/scheduledTemplateEditor/scheduledTemplateEditor.vue")
const AdminContent = () => import(/* webpackChunkName: "admin" */ "@/views/admin/content/content.vue")
const AdminDeals = () => import(/* webpackChunkName: "admin" */ "@/views/admin/deals/deals.vue")
const AdminDealsReplyTexts = () => import(/* webpackChunkName: "admin" */ "@/views/admin/deals/organizationDealsReplyTexts.vue")
const AdminDealsPostsTexts = () => import(/* webpackChunkName: "admin" */ "@/views/admin/deals/organizationDealsPostTexts.vue")
const AdminEditor = () => import(/* webpackChunkName: "admin" */ "@/views/admin/editor/editor.vue")
const AdminInsights = () => import(/* webpackChunkName: "admin" */ "@/views/admin/insights/insights.vue")
const AdminLocations = () => import(/* webpackChunkName: "admin" */ "@/views/admin/locations/locations.vue")
const AdminBrandLocations = () => import(/* webpackChunkName: "admin" */ "@/views/admin/brandLocations/brandLocations.vue")
const AdminUsers = () => import(/* webpackChunkName: "admin" */ "@/views/admin/users/users.vue")
const AdminGroups = () => import(/* webpackChunkName: "admin" */ "@/views/admin/groups/groups.vue")
const AdminTemplates = () => import(/* webpackChunkName: "admin" */ "@/views/admin/templates/templates.vue")
const AdminTemplatesPostEditor = () => import(/* webpackChunkName: "admin" */ "@/views/admin/templates/templateEditor.vue")
const AdminReview = () => import(/* webpackChunkName: "admin" */ "@/views/admin/review/review.vue")
const AdminReviewPostEditor = () => import(/* webpackChunkName: "admin" */ "@/views/admin/review/postEditor.vue")

const Backoffice = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/backoffice.vue")
const BackofficeAccount = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/account/account.vue")
const BackofficeDashboard = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/dashboard/dashboard.vue")
const BackofficeLogs = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/logs/logs.vue")
const BackofficeLogsPostErrors = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/logs/postErrorLog.vue")
const BackofficeLogsFrontendErrors = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/logs/frontendErrorLog.vue")
const BackofficeLogsServiceErrors = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/logs/serviceErrorLog.vue")
const BackofficeOrganizations = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/organizations/organizations.vue")
const BackofficeOrganizationLocations = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/organizationLocations/organizationLocations.vue")
const BackofficeOrganizationUsers = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/organizationUsers/organizationUsers.vue")
const BackofficeUsers = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/users/users.vue")
const BackofficeServiceConnections = () => import(/* webpackChunkName: "backoffice" */ "@/views/backoffice/serviceConnections/serviceConnections.vue")

Vue.use(Router)

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
		if (savedPosition)
			return savedPosition
		else
			return {x: 0, y: Navigation.scrollPosition ?? 0}
	},
	routes: [
		{
			path: "/",
			beforeEnter: (to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => void)) => void) => {
				const key = Navigation.preferredLocationKey

				next(
					key !== null
						? { path: `/${key}`, replace: true}
						: { name: "locationSelection", replace: true })
			}
		},
		{
			path: "/brand/:id(\\d+)/:subPage?",
			redirect: { name: "locationSelection", replace: true }
		},
		{
			path: "/admin/:id/feed",
			redirect: { name: "organizationSelection", replace: true }
		},
		{
			name: "login",
			path: "/login",
			component: Login,
			meta: {
				i18n: "login",
				requiresGuest: true,
				isUserBusy: true
			}
		},
		{
			name: "userAccount",
			path: "/user",
			component: UserAccountView,
			meta: {
				i18n: "userAccount",
				requiresAuthenticated: true,
				isUserBusy: true
			}
		},
		{
			name: "resetPassword",
			path: "/resetpassword",
			component: ResetPassword,
			props: route => ({ticketId: route.query.ticket}),
			meta: {
				i18n: "resetPassword",
				isUserBusy: true
			}
		},
		{
			path: "/signup",
			component: SignUp,
			meta: {
				i18n: "signup",
				isUserBusy: true
			},
			children: [
				{
					path: "",
					redirect: {name: "signUpUserSelection"}
				},
				{
					name: "signUpUserSelection",
					path: "user",
					component: SignUpUserSelection,
					meta: {
						i18n: "userSelection",
					}
				},
				{
					name: "signUpCreateOrganization",
					path: "organization",
					component: SignUpCreateOrganization,
					meta: {
						i18n: "createOrganization",
						requiresAuthenticated: true,
					}
				},
				{
					name: "signUpSelectModules",
					path: ":organizationKey/modules",
					component: SignUpSelectModules,
					meta: {
						i18n: "selectModules",
						requiresAuthenticated: true,
					}
				},
				{
					name: "signUpConnectPlatforms",
					path: ":locationKey/connect",
					component: SignUpConnectPlatforms,
					meta: {
						i18n: "connectPlatforms",
						requiresAuthenticated: true,
					}
				}
			]
		},
		{
			name: "joinOrganization",
			path: "/join",
			component: JoinOrganization,
			props: route => ({ticketId: route.query.ticket}),
			meta: {
				i18n: "invitation.joinOrganization",
				isUserBusy: true
			}
		},
		{
			name: "subscribeToOrganization",
			path: "/subscribecontent",
			component: SubscribeToOrganization,
			props: route => ({ticketId: route.query.ticket}),
			meta: {
				i18n: "invitation.subscribeToOrganization",
				isUserBusy: true
			}
		},
		{
			name: "joinLocation",
			path: "/joinlocation",
			component: JoinLocation,
			props: route => ({ticketId: route.query.ticket}),
			meta: {
				i18n: "invitation.joinLocation",
				isUserBusy: true
			}
		},
		{
			name: "createLocation",
			path: "/createlocation",
			component: CreateLocation,
			props: route => ({ticketId: route.query.ticket}),
			meta: {
				i18n: "invitation.createLocation",
				isUserBusy: true
			}
		},
		{
			name: "privacy",
			path: "/privacy",
			component: PrivacyView,
			meta: {
				i18n: "privacy",
				isUserBusy: true
			}
		},
		{
			name: "terms",
			path: "/terms",
			component: TermsView,
			meta: {
				i18n: "terms",
				isUserBusy: true
			}
		},
		{
			path: "/backoffice",
			component: Backoffice,
			meta: {
				i18n: "backoffice",
				requiresAuthenticated: true,
				manageType: "backoffice",
				isUserBusy: true
			},
			children: [
				{
					path: "",
					component: BackofficeDashboard,
					meta: {
						i18n: "dashboard"
					}
				},
				{
					path: "account",
					component: BackofficeAccount,
					meta: {
						i18n: "account"
					}
				},
				{
					path: "logs",
					component: BackofficeLogs,
					meta: {
						i18n: "logs"
					},
					children: [
						{
							path: "",
							redirect: "posts",
						}, {
							name: "postErrorLog",
							path: "posts",
							component: BackofficeLogsPostErrors,
							meta: {
								i18n: "posts"
							}
						}, {
							name: "frontendErrorLog",
							path: "frontend",
							component: BackofficeLogsFrontendErrors,
							meta: {
								i18n: "frontend"
							}
						}, {
							name: "serviceErrorLog",
							path: "services",
							component: BackofficeLogsServiceErrors,
							meta: {
								i18n: "services"
							}
						}
					]
				},
				{
					path: "organizations",
					component: BackofficeOrganizations,
					meta: {
						i18n: "organizations"
					}
				},
				{
					path: "organizations/:organizationId/locations",
					component: BackofficeOrganizationLocations,
					props: true,
					meta: {
						i18n: "organizationLocations"
					}
				},
				{
					path: "organizations/:organizationId/users",
					component: BackofficeOrganizationUsers,
					props: true,
					meta: {
						i18n: "organizationUsers"
					}
				},
				{
					path: "users",
					component: BackofficeUsers,
					props: true,
					meta: {
						i18n: "users"
					}
				},
				{
					name: "serviceConnections",
					path: "serviceConnections/:serviceConnectionId?",
					component: BackofficeServiceConnections,
					props: true,
					meta: {
						i18n: "serviceConnections"
					}
				}
			]
		},
		{
			name: "organizationSelection",
			path: "/admin",
			component: AdminOrganizationSelection,
			meta: {
				i18n: "admin.organizationSelection",
				requiresAuthenticated: true,
				isUserBusy: true
			}
		},
		{
			path: "/admin/:organizationKey",
			component: Admin,
			meta: {
				i18n: "admin",
				requiresAuthenticated: true,
				manageType: "organization"
			},
			children: [
				{
					path: "",
					redirect: () => Preferences.calendarType === CalendarType.list
						? "calendar"
						: {path: "calendar-grid", query: {cols: Preferences.calendarType === CalendarType.grid4Day ? "4" : "7"}}
				}, {
					path: "account",
					component: AdminAccount,
					meta: {
						i18n: "account"
					}
				}, {
					path: "calendar",
					component: AdminCalendar,
					meta: {
						i18n: "calendar"
					}
				}, {
					path: "calendar-grid",
					component: AdminCalendarGrid,
					meta: {
						i18n: "calendar"
					}
				}, {
					path: "calendar/editor/:templateId?",
					alias: ["calendar-grid/editor/:templateId?"],
					component: AdminScheduledTemplateEditor,
					props: route => ({
						...route.params,
						serviceType: route.query.serviceType,
						postType: route.query.postType,
						publishDate: route.query.date,
						defaultDeal: "deal" in route.query
					}),
					meta: {
						i18n: "scheduledTemplateEditor"
					}
				}, {
					path: "content",
					redirect: "content/published"
				},{
					path: "content/:state",
					component: AdminContent,
					props: true,
					meta: {
						i18n: "content"
					}
				}, {
					path: "deals",
					component: AdminDeals,
					meta: {
						i18n: "deals"
					}
				}, {
					path: "deals/replies",
					component: AdminDealsReplyTexts,
					meta: {
						i18n: "deals.replies"
					}
				}, {
					path: "deals/posts",
					component: AdminDealsPostsTexts,
					meta: {
						i18n: "deals.posts"
					}
				}, {
					path: "review",
					component: AdminReview,
					meta: {
						i18n: "review"
					},
					children: [
						{
							path: "editor/:postId?",
							component: AdminReviewPostEditor,
							props: true,
							meta: {
								i18n: "postEditor"
							}
						}
					]
				}, {
					path: "editor",
					component: AdminEditor,
					meta: {
						i18n: "editor"
					}
				}, {
					path: "insights",
					component: AdminInsights,
					meta: {
						i18n: "insights"
					}
				}, {
					path: "locations",
					component: AdminLocations,
					meta: {
						i18n: "locations"
					}
				}
				, {
					path: "brand-locations",
					component: AdminBrandLocations,
					meta: {
						i18n: "brandLocations"
					}
				}, {
					path: "users",
					component: AdminUsers,
					meta: {
						i18n: "users"
					}
				}, {
					path: "groups",
					component: AdminGroups,
					meta: {
						i18n: "groups"
					}
				}, {
					path: "templates",
					component: AdminTemplates,
					meta: {
						i18n: "templates"
					},
					children: [
						{
							path: "editor/:templateId?",
							component: AdminTemplatesPostEditor,
							props: route => ({
								...route.params,
								categoryId: route.query.category,
								serviceType: route.query.serviceType,
								defaultDeal: "deal" in route.query
							}),
							meta: {
								i18n: "templateEditor"
							}
						}
					]
				}
			]
		},
		{
			name: "locationSelection",
			path: "/",
			component: UserLocationSelection,
			meta: {
				i18n: "user.locationSelection",
				requiresAuthenticated: true,
				isUserBusy: true
			}
		},
		{
			path: "/:locationKey",
			component: User,
			meta: {
				i18n: "user",
				requiresAuthenticated: true,
				manageType: "location"
			},
			children: [
				{
					path: "",
					redirect: () => Preferences.calendarType === CalendarType.list
						? "calendar"
						: {path: "calendar-grid", query: {cols: Preferences.calendarType === CalendarType.grid4Day ? "4" : "7"}}
				}, {
					path: "account",
					component: UserAccount,
					meta: {
						i18n: "account"
					}
				}, {
					path: "account/replyTexts",
					component: UserAccountDealsReplyTexts,
					meta: {
						i18n: "account.replyTexts"
					}
				}, {
					path: "account/postTexts",
					component: UserAccountDealsPostTexts,
					meta: {
						i18n: "account.postTexts"
					}
				}, {
					path: "calendar",
					component: UserCalendar,
					meta: {
						i18n: "calendar"
					}
				}, {
					path: "calendar-grid",
					component: UserCalendarGrid,
					meta: {
						i18n: "calendar"
					},
				}, {
					path: "deals",
					component: UserDeals,
					meta: {
						i18n: "deals"
					}
				}, {
					path: "calendar/orders/:postId",
					alias: ["calendar-grid/orders/:postId", "deals/orders/:postId"],
					component: UserDealsOrders,
					props: true,
					meta: {
						i18n: "deals.orders"
					}
				}, {
					path: "calendar/editor/:postId?",
					alias: ["calendar-grid/editor/:postId?", "deals/editor/:postId?"],
					component: UserPostEditor,
					props: route => ({
						...route.params,
						serviceType: route.query.serviceType,
						postType: route.query.postType,
						publishDate: route.query.date,
						defaultDeal: "deal" in route.query
					}),
					meta: {
						i18n: "postEditor"
					}
				}, {
					path: "content",
					component: UserContent,
					meta: {
						i18n: "content"
					}
				}, {
					path: "insights",
					component: UserInsights,
					meta: {
						i18n: "insights"
					}
				}
			]
		},
		{
			path: "*",
			name: "notFound",
			component: NotFoundView,
			meta: {
				i18n: "notFound",
				isUserBusy: true
			}
		}
	]
})

routerGuardStatus(router)
routerGuardAuthentication(router)
routerGuardOrganization(router)
routerGuardLocation(router)

export default router
