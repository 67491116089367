export default class ThrottledAction {
	private readonly action: () => void
	private timeOut: number | null = null

	public get isWaiting(): boolean {
		return this.timeOut !== null
	}

	constructor(action: () => void, private readonly delayDuration: number) {
		this.action = () => {
			this.timeOut = null
			action()
		}
	}

	public delay(): void {
		this.cancel()
		this.timeOut = window.setTimeout(this.action, this.delayDuration)
	}

	public cancel(): void {
		if (this.isWaiting) {
			clearTimeout(this.timeOut!)
			this.timeOut = null
		}
	}
}
