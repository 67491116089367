import Notifications from "@/managers/session/notifications"
import Locations from "@/managers/location/locations"
import {watch} from "@/store/store"
import {localStorage} from "@/managers/system/fallbackStorage"
import router from "@/plugins/router"
import type {RawLocation, Route} from "vue-router"
import Router from "vue-router"
import Routing from "@/store/session/routing"

const InstallImport = import("@/managers/system/install")
let Install: typeof import("@/managers/system/install").default | undefined
InstallImport.then(install => Install = install.default)

class Navigation {
	public scrollPosition: number | null = null
	private readonly preferredLocationKeyKey: string = "preferredLocationKey"

	public get isRouting(): boolean {
		return Routing.isRouting
	}

	public get routingStatus(): string | null {
		return Routing.routingStatus
	}

	public get isUserBusy(): boolean {
		if (this.isRouting)
			return true

		return router.currentRoute.matched.some(r => r.meta.isUserBusy === true)
	}

	public get preferredLocationKey(): string | null {
		try {
			return localStorage.getItem(this.preferredLocationKeyKey)
		} catch (error: any) {
			Notifications.warning("Failed to get preferredLocationKey", error)
			return null
		}
	}
	public set preferredLocationKey(value: string | null) {
		try {
			if (value !== null)
				localStorage.setItem(this.preferredLocationKeyKey, value)
			else
				localStorage.removeItem(this.preferredLocationKeyKey)
		} catch (error: any) {
			Notifications.warning("Failed to set preferredLocationKey", error)
		}
	}

	constructor() {
		watch(() => Locations.main, location => {
			if (location !== null)
				this.preferredLocationKey = location.key
		})
	}

	public to(location: RawLocation): Promise<Route | void> {
		return router.push(location)
			.catch(reason => {
				if (!Router.isNavigationFailure(reason, Router.NavigationFailureType.redirected))
					throw reason
			})
	}

	public openNewTab(url: string): void {
		const newWindow = window.open(url, "_blank")

		if (newWindow)
			newWindow.focus()
	}

	public reload(): void {
		if (Install!.isUpdateReady)
			Install!.update()
		else
			window.location.reload()
	}

	public replaceQuery(changes: Record<string, string>): Promise<Route | void> {
		return router.replace({query: this.cloneQuery(changes)})
	}

	public cloneQuery(changes?: Record<string, string>): Record<string, string> {
		const query = JSON.parse(JSON.stringify(router.currentRoute.query))

		if (changes)
			for (const key in changes)
				query[key] = changes[key]

		return query
	}
}

export default new Navigation()
