import Vue from "vue"
const eventBus = new Vue()

export function sendGlobalEvent(type: globalEvents, ...args: any[]): void {
	eventBus.$emit(type, ...args)
}

export function addGlobalEventListener(type: globalEvents, handler: (...args: any[]) => void): void {
	eventBus.$on(type, handler)
}

export function removeGlobalEventListener(type: globalEvents, handler: (...args: any[]) => void): void {
	eventBus.$off(type, handler)
}

export enum globalEvents {
	organizationAdded = "organizationAdded",
	organizationChanged = "organizationChanged",
	userChanged = "userChanged",
	locationServiceChanged = "locationServiceChanged"
}
