import Locations from "@/managers/location/locations"
import PublicServiceConnection, {ServiceConnectionType} from "@/managers/data/serviceConnection/publicServiceConnection"
import State from "@/store/location/services"
import Portal from "@/managers/portal"
import List from "@/managers/base/list"
import Authentication from "@/managers/facebook/authentication"
import {when} from "@/store/store"
import User from "../user/user"
import DetailedService from "@/managers/data/serviceConnection/detailedService"
import {globalEvents, sendGlobalEvent} from "@/managers/session/globalEvents"
import type ServiceConnection from "@/managers/data/serviceConnection/serviceConnection"

class Services extends List<DetailedService, number> {
	public get hasAnyWorking(): boolean {
		return this.isReady && this.list!.some(s => s.connections.length > 0)
	}

	protected get dependent(): number | null {
		return Locations.main !== null ? Locations.main.id : null
	}

	protected get state(): typeof State {
		return State
	}

	constructor() {
		super("service connections")
	}

	public addFacebook(id: string): Promise<PublicServiceConnection> {
		return this.add(id, ServiceConnectionType.facebook)
	}

	public addInstagram(id: string): Promise<PublicServiceConnection> {
		return this.add(id, ServiceConnectionType.instagram)
	}

	public async add(id: string, type: ServiceConnectionType): Promise<PublicServiceConnection> {
		return this.addToLocation(id, type, this.dependent!)
	}

	public async addToLocation(id: string, type: ServiceConnectionType, locationId: number): Promise<PublicServiceConnection> {
		if (Authentication.authResponse === null)
			throw new Error("No Facebook auth response available")

		const newConnection = await Portal.call.locations.serviceConnectionsPost(locationId, type, id, Authentication.authResponse.accessToken, Authentication.authResponse.userID).response
			.then(PublicServiceConnection.fromApi)

		await this.updateList()

		if (this.dependent === locationId) {
			const service = this.list!.find(s => s.type === type)
			if (service && User.isReady)
				return service.connections.find(c => c.user.id === User.current!.id) ?? newConnection
		}

		sendGlobalEvent(globalEvents.locationServiceChanged, locationId, type)

		return newConnection
	}

	public async deleteFromLocation(locationId: number, type: ServiceConnectionType, all: boolean): Promise<void> {
		await Portal.call.locations.serviceConnectionsDelete(locationId, type, all).response

		if (locationId === this.dependent) {
			const serviceIndex = this.list!.findIndex(service => service.type === type)

			if (serviceIndex !== -1) {
				if (all)
					this.state.remove(serviceIndex)
				else if(User.isReady) {
					const connectionIndex = this.list![serviceIndex].connections
						.findIndex(connection => connection.user.id === User.current!.id)

					if (connectionIndex !== -1)
						this.state.removeConnection({serviceIndex, connectionIndex})
				}
			}
		}

		sendGlobalEvent(globalEvents.locationServiceChanged, locationId, type)
	}

	public async deleteConnection(locationId: number, connection: ServiceConnection): Promise<void> {
		await Portal.call.serviceConnections.delete(connection.id).response

		if (locationId === this.dependent) {
			const serviceIndex = this.list!.findIndex(service => service.type === connection.type)

			if (serviceIndex !== -1) {
				const connectionIndex = this.list![serviceIndex].connections
					.findIndex(existingConnection => existingConnection.id === connection.id)

				if (connectionIndex !== -1)
					this.state.removeConnection({serviceIndex, connectionIndex})
			}
		}

		sendGlobalEvent(globalEvents.locationServiceChanged, locationId, connection.type)
	}

	public async loadFromLocation(id: number): Promise<DetailedService[]> {
		if (this.dependent === id) {
			await when(() => this.isReady)
			return this.list!
		}
		return this.apiLoad(id)
	}

	protected apiLoad(dependent: number): Promise<DetailedService[]> {
		return Portal.call.locations.servicesGet(dependent)
			.response.then(locations => locations.map(DetailedService.fromApi))
	}
}

export {PublicServiceConnection, DetailedService, ServiceConnectionType}
export default new Services()
