import type Router from "vue-router"
import Routing from "@/store/session/routing"
import Notifications from "@/managers/session/notifications"
import Locations from "@/managers/location/locations"
import Organizations from "@/managers/organization/organizations"
import Navigation from "@/managers/session/navigation"

export default function(router: Router): void {
	router.beforeResolve((to, from, next) => {
		const locationKey = to.params.hasOwnProperty("locationKey") ? to.params.locationKey : null

		if (locationKey !== null) {
			Routing.setRoutingStatus("checkingLocation")
			Locations.setMainFromKey(locationKey)
				.then(location => {
					Organizations.setMainFromLocation(location.organizationId)
						.then(
							() => next(),
							reason => {
								Notifications.warning("Failed to set organization from location: " + reason)
								next({name: "notFound", params: {0: to.fullPath}})
							}
						)
				}, reason => {
					Notifications.warning("Failed to find location: " + reason)

					if (Navigation.preferredLocationKey === locationKey) {
						Navigation.preferredLocationKey = null
						next({name: "locationSelection"})
					} else
						next({name: "notFound", params: {0: to.fullPath}})
				})
		} else {
			if (Locations.main !== null) {
				Locations.clearMain()
				Notifications.debug("Clearing main location")
			}
			if (Organizations.wasMainSetFromLocation)
				Organizations.main = null
			next()
		}
	})
}
