import {getErrorStack} from "@/utility/data/error"
import Configuration from "@/managers/system/configuration"
import User from "@/managers/user/user"

class AwsLog {
	public event(category: string, action: string, label?: string): void {
		this.log(
			"Event: " + action,
			Level.information,
			{
				Event: {
					Category: category,
					Action: action,
					Label: label
				}
			}
		)
	}

	public error(message: string, error?: Error, fatal: boolean = true): void {
		this.log(
			message,
			fatal ? Level.error : Level.warning,
			{
				Error: error
					? {
						Name: error.name,
						Message: error.message,
						Stack: getErrorStack(error)
					}
					: null
			}
		)
	}

	public log(message: string, level: Level = Level.error, details?: Partial<ILog>): void {
		const url = new URL(Configuration.connections.errorLogPath)
		const request: RequestInit = {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "omit",
			redirect: "follow",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify({
				Level: level,
				Message: message,
				Properties: {
					... details,
					Environment: Configuration.connections.errorLogEnvironment,
					UserAgent: window.navigator.userAgent,
					Url: window.location.toString(),
					UserId: User.isReady ? User.current!.id : "Unknown",
					Version: Configuration.system.version
				}
			})
		}
		fetch(url, request)
			.catch(reason => console.error("Failed to log", reason)) // tslint:disable-line:no-console
	}
}

export enum Level {
	error = "error",
	warning = "warning",
	information = "information"
}

export interface ILog {
	Environment: string
	UserAgent: string
	Url: string
	UserId: string
	Version: string
	Error: ILogError | null
	Event: IEvent
}

export interface ILogError {
	Name: string
	Message: string
	Stack: StackFrame[]
}

export interface IEvent {
	Category: string
	Action: string
	Label?: string
}

export default new AwsLog()
