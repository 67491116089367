import { register } from "register-service-worker"
import Notifications from "@/managers/session/notifications"
import Install from "@/managers/system/install"
import Tracking from "@/managers/session/logging/tracking"

if (process.env.NODE_ENV === "production")
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready(registration) {
			Tracking.event("ServiceWorker", "Ready", undefined, true)
			Notifications.debug("Serving from cache via service worker")
		},
		registered(registration) {
			Notifications.debug("Service worker has been registered.")
		},
		cached(registration) {
			Notifications.debug("Content has been cached for offline use.")
		},
		updatefound(registration) {
			Install.updateIsAvailable()
		},
		updated(registration) {
			Install.updateIsReady(registration)
		},
		offline() {
			Install.isOffline = true
		},
		error(error: Error) {
			Tracking.event("ServiceWorker", "Error", error.message, true)
			Notifications.warning("Error during service worker registration:", error)
		}
	})
