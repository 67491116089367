import Vue from "vue"
import type { RawLocation, Route } from "vue-router"
import Router from "vue-router"

class Navigation {
	public install(vue: typeof Vue, options: any): void {
		vue.prototype.$navigateTo = function(this: Vue, location: RawLocation, replace: boolean = false) {
			return (replace ? this.$router.replace(location) : this.$router.push(location))
				.catch(reason => {
					if (!Router.isNavigationFailure(reason, Router.NavigationFailureType.redirected))
						throw reason
				})
		}
	}
}

Vue.use(new Navigation())

declare module "vue/types/vue" {
	// tslint:disable:interface-name
	interface Vue {
		$navigateTo(location: RawLocation, replace?: boolean): Promise<Route | void>
	}
}
