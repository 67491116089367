<template lang="pug">
	ConfirmDialog(
		:isOpen.sync="isVisible"
		persistent
		:header="$tk('header')"
		:confirm="$tk('update')"
		:cancel="$tk('dismiss')"
		@confirmed="update") {{$tk('text', {newVersion: newVersion})}}
</template>
<script lang="ts">
	import {Component, I18nComponentMixin} from "@/mixins/combinations"
	import Install from "@/managers/system/install"
	import ConfirmDialog from "@/components/dialogs/confirmDialog.vue"

	@Component({components: {ConfirmDialog}})
	export default class UpdateDialog extends I18nComponentMixin {
		private innerIsVisible = false

		private get newVersion(): string {
			return Install.newVersion ?? "?.?.?"
		}

		private get isVisible(): boolean {
			return this.innerIsVisible
		}

		private set isVisible(value: boolean) {
			this.innerIsVisible = value

			if (!value)
				Install.hasRequestedUpdateDialog = false
		}

		constructor() {
			super()
			this.i18nKey = "updateDialog"
		}

		public created(): void {
			this.$watch(() => Install.isImportantUpdateReady || Install.hasRequestedUpdateDialog, isReady => {
				if (isReady)
					this.isVisible = true
			}, {immediate: true})
		}

		private update(): void {
			Install.update()
		}
	}
</script>
