import Component from "vue-class-component"
import I18n from "./i18n"

@Component
export default class I18nComponent extends I18n {
	protected get i18nKey(): string | null {
		return this.innerI18nKey
	}

	protected set i18nKey(value: string | null) {
		super.i18nKey = value === null ? null : "components." + value
	}
}
