import type {IPrivateUser} from "@chaosinsight/postoffice-portalclient"
import User from "@/managers/data/user/user"
import Notifications from "@/managers/session/notifications"

export default class PrivateUser extends User {
	public username: string
	public language: string
	public termsOfUseConsent: string | null
	public marketingConsent: string | null
	public notificationConsent: boolean
	public uiVersion: string | null
	public hasPassword: boolean
	public hasBackofficeAccess: boolean

	constructor(id: string, emailAddress: string, name: string, profilePictureUrl: string | null, username: string, language: string, termsOfUseConsent: string | null, marketingConsent: string | null, notificationConsent: boolean, uiVersion: string | null, hasPassword: boolean, hasBackofficeAccess: boolean) {		super(id, emailAddress, name, profilePictureUrl)
		this.username = username
		this.language = language
		this.termsOfUseConsent = termsOfUseConsent
		this.marketingConsent = marketingConsent
		this.notificationConsent = notificationConsent
		this.uiVersion = uiVersion
		this.hasPassword = hasPassword
		this.hasBackofficeAccess = hasBackofficeAccess
	}

	public static fromApi(data: IPrivateUser): PrivateUser {
		let notificationConsent = false
		try {
			notificationConsent = JSON.parse(data.NotificationConsent).AllowAll === true
		} catch (error: any) {
			Notifications.warning(`Failed to parse notification consent "${data.NotificationConsent}"`, error)
		}

		return new PrivateUser(
			data.Id,
			data.EmailAddress,
			data.Name,
			data.ProfilePictureUrl,
			data.Username,
			data.Language,
			data.TermsOfUseConsent,
			data.MarketingConsent,
			notificationConsent,
			data.UiVersion,
			data.HasPassword,
			data.Permissions.CanModifyOrganization)
	}
}
