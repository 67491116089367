import {ServiceConnectionType} from "@chaosinsight/postoffice-portalclient"
import FacebookPage from "@/managers/data/facebook/facebookPage"
import InstagramBusinessAccount from "@/managers/data/instagram/InstagramBusinessAccount"

export default class Service {
	public externalId: string
	public externalName: string | null
	public type: ServiceConnectionType

	public get isFacebook(): boolean {
		return this.type === ServiceConnectionType.facebook
	}

	public get isInstagram(): boolean {
		return this.type === ServiceConnectionType.instagram
	}

	public get url(): string | null {
		return this.isFacebook
			? FacebookPage.getUrlFromId(this.externalId)
			: this.externalName !== null
				? InstagramBusinessAccount.getUrlFromUsername(this.externalName!)
				: null
	}

	constructor(externalId: string, name: string | null, type: ServiceConnectionType) {
		this.externalId = externalId
		this.externalName = name
		this.type = type
	}

	public compare(value: Service): number {
		if (value.type !== this.type)
			return this.type - value.type

		if (value.externalName !== this.externalName)
			return (this.externalName ?? "").localeCompare(value.externalName ?? "")

		return this.externalId.localeCompare(value.externalId)
	}
}

export {ServiceConnectionType}
