import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "../store"
import type PrivateUser from "@/managers/data/user/privateUser"

@Module({dynamic: true, namespaced: true, name: "user/user", store})
export class User extends VuexModule {
	public current: PrivateUser | null = null

	public get isReady(): boolean {
		return this.current !== null
	}

	@Mutation
	public setCurrent(value: PrivateUser | null): void {
		this.current = value
	}

	@Mutation
	public modifyCurrent(value: Partial<PrivateUser>): void {
		const current = this.current as any
		const data = value as any

		if (current === undefined)
			throw new Error("Failed to modify current user, user not set")

		for (const key in data)
			if (data[key] !== undefined)
				current[key] = data[key]
	}
}

export default getModule(User)
