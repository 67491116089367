import { render, staticRenderFns } from "./notifications.vue?vue&type=template&id=45d3b6d4&scoped=true&lang=pug"
import script from "./notifications.vue?vue&type=script&lang=ts"
export * from "./notifications.vue?vue&type=script&lang=ts"
import style0 from "./notifications.vue?vue&type=style&index=0&id=45d3b6d4&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d3b6d4",
  null
  
)

export default component.exports