<template lang="pug">
	v-toolbar(
		:height="$vuetify.breakpoint.mdAndUp ? fullscreen ? '80' : '78' : '65'"
		:class="{fullscreen, divider, 'center': !closable && !fullscreen, closeRight}"
		flat
	).dialogToolbar.flex-grow-0
		v-btn(v-if="closable" @click="close" :disabled="disabled" small depressed text fab).midnight--text
			PoIcon(icon="close" size="16")
		v-spacer(v-if="closable && closeRight")
		v-toolbar-title(v-if="hasHeader" :class="{'ml-2 ml-md-3': closable}").font-weight-bold.midnight--text {{header | capitalize}}
		v-spacer(v-if="closable && !closeRight")
		slot
</template>
<script lang="ts">
	import {Component, Prop, Vue} from "vue-property-decorator"

	@Component({components: {}})
	export default class DialogToolbar extends Vue {
		@Prop({type: Boolean})
		public readonly fullscreen!: boolean
		@Prop({type: Boolean})
		public closable!: boolean
		@Prop({type: Boolean})
		public noDivider!: boolean
		@Prop({type: Boolean})
		public closeRight!: boolean
		@Prop({type: Boolean})
		public disabled!: boolean
		@Prop({default: null})
		public header!: string | null

		private get hasHeader(): boolean {
			return this.header !== null
		}

		private get divider(): boolean {
			return !this.fullscreen && !this.noDivider
		}

		private close(): void {
			this.$emit("close")
		}
	}
</script>
<style lang="sass" scoped>
	.dialogToolbar
		&.fullscreen
			padding-right: 2px
			box-shadow: $topbar-shadow !important
			z-index: 1
		&.center
			::v-deep .v-toolbar__content
				justify-content: center
		&.closeRight
			::v-deep .v-toolbar__content
				padding: 0 10px 0 17px
				flex-direction: row-reverse
		&.divider
			border-bottom: 1px solid $po_graylight
		::v-deep .v-toolbar__title
			font-size: +rem(22)
			+mq(md)
				font-size: +rem(26)
		::v-deep .v-toolbar__content
			padding: 0 14px 0 10px
</style>
