import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store/store"
import type FacebookAccount from "@/managers/data/facebook/facebookAccount"
import type {IModifyDataPayload, IStoreListModifiable} from "@/store/data/iStoreList"

@Module({dynamic: true, namespaced: true, name: "facebook/accounts", store})
class Accounts extends VuexModule implements IStoreListModifiable<FacebookAccount> {
	public list: FacebookAccount[] | null = null
	public updater: Promise<void> | null = null
	public lastUpdateFailed: boolean = false

	public get isReady(): boolean {
		return this.list !== null
	}

	@Mutation
	public setList(value: FacebookAccount[] | null): void {
		this.list = value
		this.lastUpdateFailed = false
	}

	@Mutation
	public add(value: FacebookAccount): void {
		this.list!.push(value)
	}

	@Mutation
	public remove(index: number): void {
		this.list!.splice(index, 1)
	}

	@Mutation
	public modify(value: IModifyDataPayload<FacebookAccount>): void {
		const account = this.list![value.index] as any
		const data = value.data as any

		if (account === undefined)
			throw new Error("Failed to find account to modify")

		for (const key in data)
			if (data[key] !== undefined)
				account[key] = data[key]
	}

	@Mutation
	public setUpdater(value: Promise<void> | null): void {
		this.updater = value
	}

	@Mutation
	public setLastUpdateFailed(value: boolean): void {
		this.lastUpdateFailed = value
	}
}

export default getModule(Accounts)
