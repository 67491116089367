import {getModule, Module, Mutation, VuexModule} from "vuex-module-decorators"
import store from "../store"
import type Organization from "@/managers/data/organization/organization"
import type {IModifyDataPayload, IStoreListModifiable, IStoreMain} from "@/store/data/iStoreList"

@Module({dynamic: true, namespaced: true, name: "organization/organizations", store})
class Organizations extends VuexModule implements IStoreListModifiable<Organization>, IStoreMain<Organization>  {
	public list: Organization[] | null = null
	public mainId: number | null = null
	public wasMainSetFromLocation: boolean = false

	public get isReady(): boolean {
		return this.list !== null
	}

	@Mutation
	public setMainId(value: number | null): void {
		if (value === this.mainId)
			return
		this.mainId = value
	}

	@Mutation
	public setWasMainSetByLocation(value: boolean): void {
		this.wasMainSetFromLocation = value
	}

	@Mutation
	public setList(value: Organization[] | null): void {
		this.list = value
	}

	@Mutation
	public add(value: Organization): void {
		this.list!.push(value)
	}

	@Mutation
	public remove(index: number): void {
		this.list!.splice(index, 1)
	}

	@Mutation
	public modify(value: IModifyDataPayload<Organization>): void {
		const organization = this.list![value.index] as any
		const data = value.data as any

		if (organization === undefined)
			throw new Error("Failed to find organization to modify")

		for (const key in data)
			if (data[key] !== undefined)
				organization[key] = data[key]
	}
}

export default getModule(Organizations)
