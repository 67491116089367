import { Component, Vue } from "vue-property-decorator"
import Locations, {Location} from "@/managers/location/locations"
import type { Route } from "vue-router"
import {setInQuery, urlEncode} from "@/utility/data/url"

@Component
export default class MainLocation extends Vue {
	protected get location(): Location {
		return Locations.main!
	}

	protected get locationUrl(): string {
		return "/" + urlEncode(this.location.key)
	}

	protected getLocationUrl(subPage: string, query?: Record<string, any>): string {
		return `${this.locationUrl}/${setInQuery(subPage, query)}`
	}

	protected goToLocationUrl(subPage: string): Promise<Route | void> {
		return this.$navigateTo(this.getLocationUrl(subPage))
	}
}
