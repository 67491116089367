import {DateTime} from "luxon"
import {IOrganization, OrganizationType} from "@chaosinsight/postoffice-portalclient"
import type {IItemKeyed} from "@/managers/data/base/iItem"
import OrganizationPermission from "@/managers/data/organization/organizationPermission"
import DealConfiguration from "@/managers/data/organization/dealConfiguration"
import DateTimeUtility from "@/utility/data/dateTime"

export default class Organization implements IItemKeyed  {
	public id: number
	public name: string
	public key: string
	public type: OrganizationType
	public phone: string | null
	public industry: number
	public logoUrl: string | null
	public currency: string

	public locationSignupToken: string | null
	public contentSubscriptionToken: string | null

	public trialExpirationDate: DateTime | null
	public paidUntilDate: DateTime | null

	public isActive: boolean

	public isReviewRequired: boolean
	public isNotificationsEnabled: boolean
	public isDealsEnabled: boolean
	public isPrioritizingMessaging: boolean
	public isLiveDealsEnabled: boolean
	public isCustomersAnonymized: boolean
	public isAlcoholEnabled: boolean

	public dealConfiguration: DealConfiguration

	public permission: OrganizationPermission | null

	public locationsCount: number
	public locationsWithDealsEnabledCount: number

	public get canManage(): boolean {
		return this.permission !== null
			&& this.permission.canView
			&& (this.permission.canViewUsers
				|| this.permission.canViewContentGroups
				|| this.permission.canViewPostsForReview
				|| this.permission.canViewStatistics)
	}

	public get hasUpdatedPermission(): boolean {
		return this.permission !== null
	}

	public get isOnTrial(): boolean {
		if (this.trialExpirationDate === null)
			return false

		if (this.paidUntilDate === null)
			return true

		return this.trialExpirationDate > this.paidUntilDate
	}

	public get paidSort(): number {
		const trial =  this.trialExpirationDate?.toMillis() ?? 0
		const paid = this.paidUntilDate?.toMillis() ?? 0

		return trial >= paid ? trial : paid + DateTimeUtility.maxDate
	}

	public get isSingleStore(): boolean {
		return this.type === OrganizationType.singleStore
	}

	public get isBrand(): boolean {
		return this.type === OrganizationType.brand
	}

	constructor(
		id: number,
		name: string,
		key: string,
		type: OrganizationType,
		phone: string | null,
		industry: number,
		logoUrl: string | null,
		currency: string,
		locationSignupToken: string | null,
		contentSubscriptionToken: string | null,
		trialExpirationDate: DateTime | null,
		paidUntilDate: DateTime | null,
		isActive: boolean,
		isReviewRequired: boolean,
		isNotificationsEnabled: boolean,
		isDealsEnabled: boolean,
		isPrioritizingMessaging: boolean,
		isLiveDealsEnabled: boolean,
		isCustomersAnonymized: boolean,
		isAlcoholEnabled: boolean,
		permission: OrganizationPermission | null,
		dealConfiguration: DealConfiguration,
		locationsCount: number,
		locationsWithDealsEnabledCount: number) {
		this.id = id
		this.name = name
		this.key = key
		this.type = type
		this.phone = phone
		this.industry = industry
		this.logoUrl = logoUrl
		this.currency = currency
		this.locationSignupToken = locationSignupToken
		this.contentSubscriptionToken = contentSubscriptionToken
		this.trialExpirationDate = trialExpirationDate
		this.paidUntilDate = paidUntilDate
		this.isActive = isActive
		this.isReviewRequired = isReviewRequired
		this.isNotificationsEnabled = isNotificationsEnabled
		this.isDealsEnabled = isDealsEnabled
		this.isPrioritizingMessaging = isPrioritizingMessaging
		this.isLiveDealsEnabled = isLiveDealsEnabled
		this.isCustomersAnonymized = isCustomersAnonymized
		this.isAlcoholEnabled = isAlcoholEnabled
		this.permission = permission
		this.dealConfiguration = dealConfiguration
		this.locationsCount = locationsCount
		this.locationsWithDealsEnabledCount = locationsWithDealsEnabledCount
	}

	public static fromApi(data: IOrganization): Organization {
		return new Organization(
			data.Id,
			data.Name,
			data.Key,
			data.Type,
			data.ContactPhoneNumber,
			data.Industry,
			data.LogoUrl,
			data.Currency,
			data.LocationSignupToken,
			data.ContentSubscriptionToken,
			data.TrialExpirationDate !== null ? DateTime.fromISO(data.TrialExpirationDate) : null,
			data.PaidUntilDate !== null ? DateTime.fromISO(data.PaidUntilDate) : null,
			data.IsActive,
			data.Review.IsEnabled,
			data.Notifications.IsEnabled,
			data.Deals.Regular.IsEnabled,
			data.Deals.Common.IsMessagingPrioritized,
			data.Deals.Live.IsEnabled,
			data.Deals.Common.IsAnonymousBuyerEnabled,
			data.Deals.Common.IsAlcoholEnabled,
			null,
			DealConfiguration.fromApi(data.Deals),
			data.Statistics.Locations,
			data.Statistics.LocationsWithDeals)
	}
}

export {DealConfiguration, OrganizationPermission, OrganizationType}
