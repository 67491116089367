<template lang="pug">
	v-dialog(
		v-model="isOpenValue"
		v-bind="$attrs"
		:content-class="`basicDialog${overflowVisible ? ' overflowVisible' : ''}`"
		:transition="transition"
		:persistent="persistent"
		:scrollable="scrollable"
		:width="width"
		overlay-opacity="1"
	)
		template(#activator="props")
			slot(name="activator" :on="props.on" :attrs="props.attrs")
		v-card(:height="height" :class="{wideMargins, fullscreen: $attrs.fullscreen}").elevation-0
			slot(v-if="$slots.toolbar" name="toolbar")
				template(#toolbar)
			DialogToolbar(v-else :header="header" :disabled="disableClose" :closable="closable" :no-divider="noDivider || wideMargins" @close="close" :closeRight="closeRight")
				slot(name="headerActions")
			v-card-text(:class="{'pt-1': noDivider || wideMargins}").mb-auto.pa-4
				slot
			template(v-if="$slots.actions")
				v-card-actions(:class="{actionsDivider}").actions.px-2.pb-2.pa-md-4
					slot(name="actions")
</template>
<script lang="ts">
	import { Component, Mixins, Prop } from "vue-property-decorator"
	import BaseDialog from "@/components/dialogs/mixins/baseDialog"
	import DialogToolbar from "@/components/dialogs/dialogToolbar.vue"

	@Component({components: {DialogToolbar}})
	export default class BasicDialog extends Mixins(BaseDialog) {
		@Prop({default: null})
		public readonly isOpen!: boolean | null
		@Prop({required: false})
		public readonly width?: number
		@Prop({required: false})
		public readonly height?: number
		@Prop({type: Boolean})
		public readonly persistent!: boolean
		@Prop({type: Boolean})
		public readonly scrollable!: boolean
		@Prop({type: Boolean})
		public readonly wideMargins!: boolean
		@Prop({type: Boolean})
		public readonly noDivider!: boolean
		@Prop({type: Boolean})
		public readonly closable!: boolean
		@Prop({type: Boolean})
		public readonly actionsDivider!: boolean
		@Prop({type: Boolean})
		public readonly closeRight!: boolean
		@Prop({type: Boolean})
		public readonly disableClose!: boolean
		@Prop({type: Boolean})
		public readonly overflowVisible!: boolean
		@Prop({default: "po-slide"})
		public readonly transition!: string

		private innerIsOpen = false

		private get isOpenValue(): boolean {
			return this.isOpen !== null ? this.isOpen : this.innerIsOpen
		}

		private set isOpenValue(value: boolean) {
			this.innerIsOpen = value
			this.$emit("update:isOpen", value)
		}

		private close(): void {
			this.$emit("close")
			this.isOpenValue = false
		}
	}
</script>
<style lang="sass" scoped>
	.wideMargins
		padding: 12px 0 0
		+mq(sm)
			padding: 26px
		+mq(md)
			padding: 26px 40px 40px
		.v-card__text
			padding-bottom: 12px !important
	.fullscreen
		+mq-max(sm)
			display: flex
			flex-flow: column nowrap
			.actions
				position: fixed
				inset: auto 0 0 0
				background: white
	::v-deep .overflowVisible
		overflow-y: auto
		+mq(md)
			overflow: visible
	.actions
		&.actionsDivider
			border-top: 1px solid $po_graylight
</style>
