<template lang="pug">
	BasicDialog(
		:isOpen="isOpen"
		:header="$tk('header')"
		ansition="dialog-bottom-transition"
		max-width="800"
		persistent
	)
		.text(v-tk="'text'")
		table.cookies.mt-3
			tbody
				tr(v-for="cookie in cookies" :key="cookie.name")
					td {{cookie.name}}
					td
						div {{cookie.description}}
		template(#actions)
			.d-flex.flex-grow-1.flex-sm-grow-0.mx-auto
			BigButton(@click="deny" secondary).ma-1 {{$tk("deny")}}
			BigButton(@click="allow").ma-1 {{$tk("allow")}}
</template>
<script lang="ts">
	import {Component, I18nComponentMixin} from "@/mixins/combinations"
	import BasicDialog from "@/components/dialogs/basicDialog.vue"
	import Tracking from "@/managers/session/logging/tracking"
	import BigButton from "@/components/input/bigButton.vue"
	import Authentication from "@/managers/authentication/authentication"
	import Navigation from "@/managers/session/navigation"

	@Component({components: {BigButton, BasicDialog}})
	export default class CookieConsentDialog extends I18nComponentMixin {
		private get isOpen(): boolean {
			return !Tracking.hasUpdatedCanTrack && !Authentication.isLoggingIn && !Authentication.isAuthenticated && !Navigation.isRouting
		}

		private get cookies(): ICookie[] {
			return this.$tk("cookies") as any as ICookie[]
		}

		constructor() {
			super()
			this.i18nKey = "cookieConsentDialog"
		}

		private allow(): void {
			Tracking.updateCanTrackUnauthenticated(true)
		}

		private deny(): void {
			Tracking.updateCanTrackUnauthenticated(false)
		}
	}

	interface ICookie {
		name: string
		description: string
	}
</script>
<style lang="sass" scoped>
	.cookies
		width: 100%
		td
			padding: 4px
			border-bottom: 1px solid $po_graylight
			&:first-child
				font-weight: bold
				text-wrap: nowrap
			&:last-child
				width: auto
				text-align: left !important
</style>
