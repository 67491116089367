/* tslint:disable:max-classes-per-file */
import type IItem from "@/managers/data/base/iItem"
import type {IStoreItemList} from "@/store/data/iStoreList"
import List from "@/managers/base/list"
import {when} from "@/store/store"
import {toItemMap} from "@/utility/structure/array"

export default abstract class ItemList<T extends IItem, U> extends List<T, U> {
	protected abstract get state(): IStoreItemList<T>

	public getById(id: T["id"]): T | null {
		if (this.list === null)
			return null

		for (const item of this.list)
			if (item.id === id)
				return item

		return null
	}

	public getMap(): Map<T["id"], T> {
		if (this.list === null)
			throw new Error("List is now ready")

		return toItemMap(this.list!)
	}

	public async getMapWhenReady(): Promise<Map<T["id"], T>> {
		await when(() => this.isReady)
		return this.getMap()
	}

	protected getIndex(id: T["id"]): number | null {
		if (this.state.list === null)
			return null

		const index = this.state.list!.findIndex(item => item.id === id)

		return index !== -1 ? index : null
	}
}

export {IStoreItemList}
