import ListEditable from "@/managers/base/listEditable"
import type IItem from "@/managers/data/base/iItem"
import type {IStoreListModifiable} from "@/store/data/iStoreList"

export default abstract class ListModifiable<T extends IItem, U> extends ListEditable<T, U> {
	protected abstract get state(): IStoreListModifiable<T>

	protected modifyItem(item: Partial<T> & Pick<T, "id">): T | null {
		const index = this.getIndex(item.id)

		if (index === null)
			return null

		this.state.modify({index, data: item})
		return this.list![index]
	}
}

export {IStoreListModifiable}
