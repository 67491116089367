import Loader, {call} from "@/managers/facebook/loader"
import State from "@/store/facebook/authentication"
import Notifications from "@/managers/session/notifications"
import Configuration from "@/managers/system/configuration"
import {sessionStorage} from "@/managers/system/fallbackStorage"
import Tracking from "@/managers/session/logging/tracking"

class Authentication {
	private readonly logOutStorage = {key: "LoggedOut", value: "yes"}

	public get isLoggingIn(): boolean {
		return State.isLoggingIn
	}

	public get hasCheckedLogin(): boolean {
		return State.hasCheckedLogin
	}

	public get isLoggedIn(): boolean {
		return State.isLoggedIn
	}

	public get canLogin(): boolean {
		return State.canLogin
	}

	public get hasModuleFailed(): boolean {
		return State.hasModuleFailed
	}

	public get authResponse(): IFacebookAuthResponse | null {
		return State.authResponse
	}

	public constructor() {
		Loader.then(facebook => {
			facebook.getLoginStatus(response => {
				this.authResponseChanged(response)

				if (response.status === "unknown")
					this.handleInitialUnknown(facebook)

				//noinspection TypeScriptValidateTypes
				facebook!.Event.subscribe("auth.authResponseChange", r => this.authResponseChanged(r))
				Tracking.event("Facebook", "Initial status", response.status, true)
			}, false)
		}, reason => {
			State.setHasModuleFailed(true)
			State.setHasCheckedLogin(true)

			const message = reason
				? reason instanceof Error
					? reason.message
					: reason.toString()
				: "unknown"

			Notifications.warning("Failed to load Facebook module", reason instanceof Error ? reason : undefined)
			Tracking.event("Facebook", "Failed to load module", message, true)
		})
	}

	public async login(): Promise<boolean> {
		this.clearLogOutCookie()

		if (State.isLoggedIn)
			return true
		else if (!State.canLogin)
			return false
		else
			return this.requestPermissions([])
	}

	public requestPermissions(permissions: string[]): Promise<boolean> {
		if (!this.isLoggedIn)
			State.setIsLoggingIn(true)

		return Loader.then(facebook => {
			return new Promise(((resolve, reject) => {
				try {
					facebook!.login(response => {
						State.authResponseChanged(response)
							.then(
								() => resolve(State.isLoggedIn && permissions.every(p => State.grantedPermissions.indexOf(p) !== -1)),
								reason => reject(reason))
					}, { scope: permissions.join(","), return_scopes: true})
				} catch (error: any) {
					reject(new Error("Failed to login to Facebook: " + (error.message ?? error.toString())))
				}
			}))
		})
	}

	public async logOut(): Promise<void> {
		sessionStorage.setItem(this.logOutStorage.key, this.logOutStorage.value)
		return Loader.then(facebook => {
			return new Promise<void>((resolve, reject) => {
				facebook!.logout(response => {
					State.setHasHandledInitialUnknown(false)
					resolve()
				})
			})
		})
	}

	private authResponseChanged(response: IFacebookUserAuthenticate): void {
		State.authResponseChanged(response)
			.then(() => {
				if (State.isLoggedIn && !State.hasCheckedPermissions)
					this.updatePermissions()
			})
	}

	private updatePermissions(): Promise<void> {
		return call<IFacebookApiDataResponse<{ status: string; permission: string; }>>("me/permissions", {})
			.then(response => {
				const permissions: string[] = []

				if (response !== undefined && response.data !== undefined)
					for (const permission of response.data)
						if (permission.status === "granted")
							permissions.push(permission.permission)
				State.updatePermissions(permissions)
				Tracking.event("Facebook", "Permissions", permissions.join(", "), true)
			})
	}

	private handleInitialUnknown(facebook: IFacebook): void {
		if (sessionStorage.getItem(this.logOutStorage.key) === this.logOutStorage.value || State.hasHandledInitialUnknown)
			return

		State.setHasHandledInitialUnknown(true)

		this.clearLogOutCookie()

		facebook!.getLoginStatus(response =>
			State.authResponseChanged(response), true)

		Tracking.event("Facebook", "Initial unknown", undefined, true)
	}

	private clearLogOutCookie(): void {
		sessionStorage.removeItem(this.logOutStorage.key)
		this.clearCookieOnAllDomainsAndPaths(`fblo_${Configuration.connections.facebookAppId}`)
	}

	private clearCookieOnAllDomainsAndPaths(name: string): void {
		this.clearCookie(name)
		this.clearCookie(name, ".postofficesocial.com")
		this.clearCookie(name, ".postofficesocial.com", "/")
		this.clearCookie(name, ".app.postofficesocial.com")
		this.clearCookie(name, ".app.postofficesocial.com", "/")
	}

	private clearCookie(name: string, domain?: string, path?: string): void {
		let command = `${name}=;`

		if (domain)
			command += ` domain=${domain};`

		if (path)
			command += ` path=${path};`

		document.cookie = `${command} expires=Thu, 01 Jan 1970 00:00:01 GMT;`
	}
}

export default new Authentication()
