import type {IDealTextConfiguration, IDealTextWithLanguage, IOrganizationDealsConfiguration} from "@chaosinsight/postoffice-portalclient"
import DealTexts, {type DealTextKeys} from "@/managers/data/organization/dealTexts"
import {flatten} from "@/utility/structure/array"

export default class DealConfiguration {
	public texts!: Map<string, DealTexts>

	private constructor() {
	}

	public toApi(): IDealTextConfiguration<IDealTextWithLanguage[]> {
		return {
			Common: {
				Texts: {
					ExpiredHeader: this.getDealTextsByKey("expiredHeader"),
					OutOfStockHeader: this.getDealTextsByKey("outOfStockHeader"),
					AlcoholFooter: this.getDealTextsByKey("alcoholFooter"),
					PleaseTryAgain: this.getDealTextsByKey("pleaseTryAgain"),
					PickupReminder: this.getDealTextsByKey("pickupReminder"),
					PickupReminderLastDay: this.getDealTextsByKey("pickupReminderLastDay")
				}
			},
			Regular: {
				Texts: {
					Footer: this.getDealTextsByKey("footer"),
					VariantFooter: this.getDealTextsByKey("variantFooter"),
					Confirmation: this.getDealTextsByKey("confirmation"),
					ConfirmationWithLink: this.getDealTextsByKey("confirmationWithLink"),
					ExceedsMaxAllowedQuantity: this.getDealTextsByKey("exceedsMaxAllowedQuantity"),
					ExceedsStock: this.getDealTextsByKey("exceedsStock"),
					Expired: this.getDealTextsByKey("expired"),
					OutOfStock: this.getDealTextsByKey("outOfStock")
				}
			},
			Live: {
				Texts: {
					LiveFooter: this.getDealTextsByKey("liveFooter"),
					RobotDisconnectedHeader: this.getDealTextsByKey("robotDisconnectedHeader"),
					Confirmation: this.getDealTextsByKey("liveConfirmation"),
					ConfirmationWithLink: this.getDealTextsByKey("liveConfirmationWithLink"),
					OrderCheckout: this.getDealTextsByKey("orderCheckout"),
					RobotConnected: this.getDealTextsByKey("robotConnected"),
					ProductSoldOut: this.getDealTextsByKey("productSoldOut")
				}
			}
		}
	}

	public toOrganizationApi(isDealsEnabled: boolean, isLiveDealsEnabled: boolean, isAnonymousBuyerEnabled: boolean, isMessagingPrioritized: boolean, isAlcoholEnabled: boolean): IOrganizationDealsConfiguration {
		const value = this.toApi() as Partial<IOrganizationDealsConfiguration>

		value.Common!.IsAnonymousBuyerEnabled = isAnonymousBuyerEnabled
		value.Common!.IsAlcoholEnabled = isAlcoholEnabled
		value.Common!.IsMessagingPrioritized = isMessagingPrioritized
		value.Regular!.IsEnabled = isDealsEnabled
		value.Live!.IsEnabled = isLiveDealsEnabled

		return value as IOrganizationDealsConfiguration
	}

	public fromApi(data: IDealTextConfiguration<IDealTextWithLanguage[]>, requiredLanguage?: string): this {
		this.texts = DealTexts.eachLanguageFromApi(data)

		if (requiredLanguage !== undefined && this.texts.size === 0)
			this.texts.set(requiredLanguage, DealTexts.empty(requiredLanguage))

		return this
	}

	public clone(texts: DealTexts): DealConfiguration {
		const clone = new DealConfiguration()
		clone.texts = new Map(Array.from((this.texts).values()).map(t => [t.language, texts.language === t.language ? texts : t.clone()]))

		if (!clone.texts.has(texts.language))
			clone.texts.set(texts.language, texts)

		return clone
	}

	private getDealTextsByKey(key: DealTextKeys): IDealTextWithLanguage[] {
		return flatten(Array.from(this.texts.values()).map(texts => this.getDealTexts(texts[key], texts.language)))
	}

	private getDealTexts(values: string[], language: string): IDealTextWithLanguage[] {
		return values.map(value => ({Text: value, Language: language}))
	}

	public static fromApi(data: IDealTextConfiguration<IDealTextWithLanguage[]>, requiredLanguage?: string): DealConfiguration {
		return new this()
			.fromApi(data, requiredLanguage)
	}
}

export {DealTexts}
