import GlobalSiteTag from "@/managers/session/logging/globalSiteTag"
import AWSLog, {Level} from "@/managers/session/logging/awsLog"

class Log {
	public event(category: string, action: string, label?: string): void {
		GlobalSiteTag.logEvent(action, {category, label: label ?? ""})
		AWSLog.event(category, action, label)
	}

	public error(message: string, error?: Error, fatal: boolean = true): void {
		GlobalSiteTag.logEvent("exception", {name: error?.name ?? "", description: error?.message ?? "", fatal: fatal ? "true" : "false"})
		AWSLog.error(message, error, fatal)
	}

	public informationNotification(message: string): void {
		AWSLog.log(message, Level.information)
	}

	public login(method: string): void {
		GlobalSiteTag.logEvent("login", {method})
		AWSLog.event("Authentication", "login", method)
	}

	public signUp(method: string): void {
		GlobalSiteTag.logEvent("sign_up", {method})
		AWSLog.event("Authentication", "signUp", method)
	}
}

export default new Log()
