import type IItem from "@/managers/data/base/iItem"

export default class User implements IItem  {
	public id: string
	public emailAddress: string
	public name: string
	public profilePictureUrl: string | null

	public get initials(): string {
		const initials = this.name.split(" ")
			.filter(part => part.length > 0)
			.map(part => part[0].toLocaleUpperCase())
		switch (initials.length) {
			case 0:
				return "?"
			case 1:
				return initials[0]
			default:
				return initials[0] + initials[initials.length - 1]
		}
	}

	constructor(id: string,	emailAddress: string, name: string, profilePictureUrl: string | null) {
		this.id = id
		this.emailAddress = emailAddress
		this.name = name
		this.profilePictureUrl = profilePictureUrl
	}

	public updaterFromUser(other: User): void {
		this.emailAddress = other.emailAddress
		this.name = other.name
		this.profilePictureUrl = other.profilePictureUrl
	}
}
