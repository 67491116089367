import type {ILocation} from "@chaosinsight/postoffice-portalclient"
import type {IItemKeyed} from "@/managers/data/base/iItem"
import type PublicUser from "@/managers/data/user/publicUser"
import DealConfiguration from "@/managers/data/organization/dealConfiguration"

export default class Location implements IItemKeyed  {
	public id: number
	public userDefinedId: string | null
	public organizationId: number
	public name: string
	public key: string
	public timeZone: string
	public logoUrl: string | null
	public language: string

	public lastActivityOn: Date | null
	public userCount: number
	public followers: number

	public isSharingInsightsWithOrganization: boolean
	public isDealsEnabled: boolean
	public isPrioritizingMessaging: boolean | null
	public isLiveDealsEnabled: boolean
	public isLikeCommentOnOrderEnabled: boolean
	public isPickupReminderEnabled: boolean

	public dealConfiguration: DealConfiguration

	public users: PublicUser[] | null

	constructor(
		id: number, userDefinedId: string | null, organizationId: number, key: string, name: string, timeZone: string, logoUrl: string | null, language: string,
		lastActivityOn: Date | null, userCount: number, followers: number, isSharingInsightsWithOrganization: boolean, isDealsEnabled: boolean,
		isPrioritizingMessaging: boolean | null, isLiveDealsEnabled: boolean, isLikeCommentOnOrderEnabled: boolean, isPickupReminderEnabled: boolean,
		dealConfiguration: DealConfiguration,
		users: PublicUser[] | null) {
		this.id = id
		this.userDefinedId = userDefinedId
		this.organizationId = organizationId
		this.name = name
		this.key = key
		this.timeZone = timeZone
		this.logoUrl = logoUrl
		this.language = language
		this.lastActivityOn = lastActivityOn
		this.userCount = userCount
		this.followers = followers
		this.isSharingInsightsWithOrganization = isSharingInsightsWithOrganization
		this.isDealsEnabled = isDealsEnabled
		this.isPrioritizingMessaging = isPrioritizingMessaging
		this.isLiveDealsEnabled = isLiveDealsEnabled
		this.isLikeCommentOnOrderEnabled = isLikeCommentOnOrderEnabled
		this.isPickupReminderEnabled = isPickupReminderEnabled
		this.dealConfiguration = dealConfiguration
		this.users = users
	}

	public static fromApi(data: ILocation): Location {
		return new Location(
			data.Id,
			data.UserDefinedId,
			data.OrganizationId,
			data.Key,
			data.Name,
			data.TimeZone,
			data.LogoUrl,
			data.Language,
			data.Statistics.LastActivityOn !== null ? new Date(data.Statistics.LastActivityOn) : null,
			data.Statistics.UserCount,
			data.Statistics.Followers,
			data.Statistics.IsShareInsightsWithOrganizationEnabled,
			data.Deals.Regular.IsEnabled,
			data.Deals.Common.IsMessagingPrioritized,
			data.Deals.Live.IsEnabled,
			data.Deals.Common.IsLikeCommentOnOrderEnabled,
			data.Deals.Common.IsPickupReminderEnabled,
			DealConfiguration.fromApi(data.Deals, data.Language),
			null)
	}
}
