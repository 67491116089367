import {getModule, Module, Mutation, VuexModule} from "vuex-module-decorators"
import store from "../store"
import {InstallState} from "@/store/data/installState"

@Module({dynamic: true, namespaced: true, name: "system/install", store})
export class Install extends VuexModule {
	public current = InstallState.current
	public newVersion: [number, number, number] | null = null
	public hasRequestedUpdateDialog = false
	public isOffline = false

	@Mutation
	public setCurrent(value: InstallState): void {
		this.current = value
	}

	@Mutation
	public setNewVersion(value: [number, number, number] | null): void {
		this.newVersion = value
	}

	@Mutation
	public setIsOffline(value: boolean): void {
		this.isOffline = value
	}

	@Mutation
	public setHasRequestedUpdateDialog(value: boolean): void {
		this.hasRequestedUpdateDialog = value
	}
}

export default getModule(Install)
