import type {TranslateResult} from  "vue-i18n"

export default class SelectOption<T> implements ISelectOption<T>{
	public readonly value: T
	public readonly text: string
	public readonly icon?: string

	constructor(value: T, text: string | TranslateResult, icon?: string) {
		this.value = value
		this.text = text.toString()
		this.icon = icon
	}

	public toString(): string {
		return this.text
	}

	public static createSimple(value: string): SelectOption<string> {
		return new SelectOption<string>(value, value)
	}
}

export interface ISelectOption<T> {
	readonly value: T
	readonly text: string
	readonly icon?: string
}
