import {Settings} from "luxon"

Settings.defaultLocale = "da-DK" // Ensure week start and weekend are consistent

import "core-js/stable"
import "regenerator-runtime/runtime"

import Vue from "vue"

import PoIcon from "@/components/output/poIcon.vue"
Vue.component("PoIcon", PoIcon)

import "./plugins/routerHooks"
import "vue-class-component/hooks"
import "./plugins/dateFormat"
import "./plugins/navigation"
import i18n from "./plugins/i18n"
import router from "./plugins/router"
import store from "./store/store"
import vuetify from "./plugins/vuetify"

import "./filters/capitalize"

import "./registerServiceWorker"

import {localStorage} from "@/managers/system/fallbackStorage"

import App from "./app.vue"
import "./errorHandling"
import "@/managers/session/support"

handleOldStorage()

Vue.config.productionTip = false
Vue.config.keyCodes = {period: 190, semicolon: 186}

const vue = new Vue({
	router,
	vuetify,
	i18n,
	store,
	render: h => h(App)
}).$mount("#app")

function handleOldStorage(): void {
	const oldKeys = ["InterfaceLanguage", "MainAccount", "MainBrand"]
	const language = localStorage.getItem("InterfaceLanguage")

	if (language !== null && i18n.availableLocales.indexOf(language) !== -1)
		i18n.locale = language

	oldKeys.forEach(key => localStorage.removeItem(key)) // Remove storage from old version
}
