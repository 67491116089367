import type {IOrganizationPermission} from "@chaosinsight/postoffice-portalclient"

export default class OrganizationPermission {
	public readonly canView: boolean
	public readonly canModify: boolean
	public readonly canDelete: boolean
	public readonly canViewUsers: boolean
	public readonly canModifyUsers: boolean
	public readonly canRemoveUsers: boolean
	public readonly canViewPostsForReview: boolean
	public readonly canModifyPostsForReview: boolean
	public readonly canViewContentGroups: boolean
	public readonly canModifyContentGroups: boolean
	public readonly canModifyFilters: boolean
	public readonly canViewStatistics: boolean

	constructor(
		canView: boolean, canModify: boolean, canDelete: boolean,
		canViewUsers: boolean, canModifyUsers: boolean, canRemoveUsers: boolean,
		canViewPostsForReview: boolean, canModifyPostsForReview: boolean,
		canViewContentGroups: boolean, canModifyContentGroups: boolean,
		canModifyFilters: boolean, canViewStatistics: boolean) {
		this.canView = canView
		this.canModify = canModify
		this.canDelete = canDelete
		this.canViewUsers = canViewUsers
		this.canModifyUsers = canModifyUsers
		this.canRemoveUsers = canRemoveUsers
		this.canViewPostsForReview = canViewPostsForReview
		this.canModifyPostsForReview = canModifyPostsForReview
		this.canViewContentGroups = canViewContentGroups
		this.canModifyContentGroups = canModifyContentGroups
		this.canModifyFilters = canModifyFilters
		this.canViewStatistics = canViewStatistics
	}

	public static fromApi(data: IOrganizationPermission): OrganizationPermission {
		return new OrganizationPermission(data.CanView, data.CanModify, data.CanDelete,
			data.CanViewUsers, data.CanModifyUsers, data.CanRemoveUsers,
			data.CanViewPostsForReview, data.CanModifyPostsForReview,
			data.CanViewContentGroups, data.CanModifyContentGroups,
			data.CanModifyFilters, data.CanViewStatistics)
	}
}
