const userAgent = window.navigator.userAgent

export const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints)
export const isPWA = window.matchMedia("(display-mode: standalone)").matches
export const isFireFox = userAgent.indexOf("FireFix") !== -1
export const isChrome = userAgent.indexOf("chrome") !== -1 || userAgent.indexOf("Chrome") !== -1
export const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)


const platform = getPlatform()
export const isWindows = /(Win)/i.test(platform)
export const isMacOS = /(Mac)/i.test(platform)
export const isLinux = /(Linux)/i.test(platform)
export const isUnix = /(X11)/i.test(platform)
export const isIOS = /(iPhone|iPod|iPad)/i.test(platform)
export const isAndroid = /(android)/i.test(platform)

export const intlSupport = !!Intl
export const intlNumberSupport = intlSupport && !!Intl.NumberFormat
export const intlNumberFormatRangeSupport = intlNumberSupport && !!Intl.NumberFormat.prototype.formatRange

function getPlatform(): string {
	if ((navigator as any).userAgentData) {
		return (navigator as any).userAgentData.platform
	}
	if (navigator.platform ) {
		if (navigator.userAgent && /android/.test(navigator.userAgent)) {
			// android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
			return "android"
		}
		return navigator.platform
	}
	return "unknown"
}
