import Notifications from "@/managers/session/notifications"
import Portal from "@/managers/portal"
import Facebook from "@/managers/facebook/authentication"
import FacebookAuthentication from "@/managers/authentication/facebook"
import SavedLogin from "@/managers/authentication/savedLogin"
import State from "@/store/authentication/authentication"
import {watch, when} from "@/store/store"
import Tracking from "@/managers/session/logging/tracking"

class Authentication {
	private sessionExpiredHandling: Promise<boolean> | null = null

	public get isLoggingIn(): boolean {
		return State.isLoggingIn
	}

	public get isAuthenticated(): boolean {
		return State.isAuthenticated
	}

	public get canLogin(): boolean {
		return !this.isAuthenticated && !this.isLoggingIn
	}

	public constructor() {
		this.listenForAuthentication()

		State.setIsCheckingLogin(true)
		this.attemptLogin()
			.finally(() => {
				State.setIsCheckingLogin(false)
				this.handleSessionExpired()
			})
	}

	public getAuthenticationType(): string | null {
		return Portal.client.authenticationType
	}

	public async logOut(): Promise<void> {
		if (this.isLoggingIn)
			throw new Error("Can't log out when currently logging in")

		if (!this.isAuthenticated)
			return

		if (Facebook.isLoggedIn)
			await Facebook.logOut()
		Portal.client.updateSession(null)
		Tracking.event("Authentication", "Log out")
	}

	public watchIsAuthenticated(callback: (isAuthenticated: boolean) => void, immediate: boolean = false): () => void {
		return watch(() => this.isAuthenticated, callback, {immediate})
	}

	public async checkLogin(): Promise<boolean> {
		await when(() => Facebook.hasCheckedLogin && !State.isLoggingIn && !State.isCheckingLogin)

		return this.isAuthenticated
	}

	public takeOverSession(sessionId: string): void{
		Notifications.debug("Taking over session")
		Portal.client.updateSession({Id: sessionId, DateCreated: Date.now(), DateModified: Date.now()})
		Portal.client.setAuthenticated("TakeOver")
	}

	private handleSessionExpired(): void {
		Portal.sessionExpiredHandler = () => {
			if (this.sessionExpiredHandling === null)
				this.sessionExpiredHandling = this.attemptLogin()
					.catch(
						reason => {
							Notifications.warning("Failed to reauthenticate after expired session", reason)
							return false
						}
					)
					.then(success => {
						this.sessionExpiredHandling = null
						if (!success)
							Portal.clearSession()
						return success
					})
			return this.sessionExpiredHandling
		}
	}

	private listenForAuthentication(): void {
		const authenticated = (isAuthenticated: boolean) => {
			if (isAuthenticated) {
				SavedLogin.save()
				Tracking.login(Portal.client.authenticationType ?? "unknown")
			} else
				SavedLogin.clear()

			State.setIsAuthenticated(isAuthenticated)
			Portal.client.whenIsAuthenticatedChange.then(authenticated)
		}

		if (Portal.client.isAuthenticated)
			authenticated(true)
		else
			Portal.client.whenIsAuthenticatedChange.then(authenticated)
	}

	private async attemptLogin(): Promise<boolean> {
		try {
			if (SavedLogin.canLogin) {
				await SavedLogin.login()
				return true
			}
		} catch (error: any) {
			Notifications.warning(`Failed to use saved login: ${error.message}`)
		}

		await when(() => Facebook.hasCheckedLogin)

		try {
			if (FacebookAuthentication.canLogin) {
				await FacebookAuthentication.login()
				return true
			}
		} catch (error: any) {
			if (FacebookAuthentication.isUserNotKnownError(error))
				Notifications.warning(`Failed login with unknown Facebook user`)
			else
				Notifications.warning(`Failed login with Facebook: ${error.message}`)
		}

		return false
	}
}

export default new Authentication()
