import { Component } from "vue-property-decorator"
import type {TranslateResult} from "vue-i18n"
import MainOrganization from "@/mixins/mainOrganization"
import Localization from "@/managers/session/localization"
import {intlNumberFormatRangeSupport} from "@/managers/system/capabilities"

@Component
export default class CurrencyComponent extends MainOrganization {
	protected $c(value: number, useSymbol: boolean = false): TranslateResult {
		return this.$n(value,
			{key: "currency",
				currency: this.organization.currency,
				currencyDisplay: useSymbol ? "narrowSymbol" : "code"
			})
	}

	protected $cr(startValue: number, endValue: number, useSymbol: boolean = false): string {
		if (!intlNumberFormatRangeSupport)
			return this.$c(startValue, useSymbol).toString() + " - " + this.$c(endValue, useSymbol).toString()

		return Localization.getCurrencyFormat(this.organization.currency, useSymbol)
			.formatRange(startValue, endValue)
	}
}
