import ItemList from "@/managers/base/itemList"
import type IItem from "@/managers/data/base/iItem"
import type {IStoreItemListEditable} from "@/store/data/iStoreList"

export default abstract class ListEditable<T extends IItem, U> extends ItemList<T, U> {
	protected abstract get state(): IStoreItemListEditable<T>

	public async delete(id: T["id"]): Promise<void> {
		if (this.dependent === null)
			throw new Error("Dependent not set")

		await this.apiDelete(this.dependent, id)

		if (!this.isReady)
			return

		const index = this.getIndex(id)

		if (index !== null)
			this.state.remove(index)
	}

	protected async createItem(dependent: U, createTask: Promise<T>): Promise<T> {
		const item = await createTask

		if (this.dependent === dependent)
			this.state.add(item)

		return item
	}

	protected abstract apiDelete(dependent: U, id: T["id"]): Promise<void>
}

export {IStoreItemListEditable}
