import {Vue} from "@/mixins/combinations"
import {localStorage} from "@/managers/system/fallbackStorage"

class SignUpModules {
	public readonly scheduledModule = "scheduleContent"
	public readonly dealsModule = "commentSales"
	public readonly liveModule = "liveShopping"

	private readonly selectedModulesKey = "selectedModules"
	private readonly state: {modules: string[]}

	public get modules(): string[] {
		return this.state.modules.slice()
	}

	constructor() {
		this.state = Vue.observable({modules: this.getModules()})
	}

	public setModules(modules: string[]) {
		modules = this.filterModules(modules)

		this.state.modules = modules
		localStorage.setItem(this.selectedModulesKey, modules.join(","))
	}

	public isUsingCommerce(modules: string []): boolean {
		return modules.indexOf(this.dealsModule) !== -1 || modules.indexOf(this.liveModule) !== -1
	}

	private getModules(): string[] {
		const raw = localStorage.getItem(this.selectedModulesKey)
		if (raw === null)
			return []

		return this.filterModules(raw.split(","))
	}

	private filterModules(modules: string[]): string[] {
		const validModules = [this.scheduledModule, this.dealsModule, this.liveModule]

		return modules.filter(module => validModules.indexOf(module) !== -1)
	}
}

export default new SignUpModules()
