import { Component, Vue } from "vue-property-decorator"
import type {TranslateResult, Values} from "vue-i18n"

@Component
export default class I18n extends Vue {
	private innerI18nKeyValue: string | null = null

	protected get innerI18nKey(): string | null {
		if (this.innerI18nKeyValue === null)
			throw new Error("i18nKey is not set on " + this.constructor.name)
		return this.innerI18nKeyValue + "."
	}

	protected set innerI18nKey(value: string | null) {
		if (value === null)
			throw new Error("i18nKey can not be set to null on " + this.constructor.name)

		this.innerI18nKeyValue = value
	}

	protected get i18nKey(): string | null {
		return this.innerI18nKey
	}

	protected set i18nKey(value: string | null) {
		this.innerI18nKey = value
	}

	protected $tp(path: string): string {
		return this.i18nKey! + path
	}

	protected $tk(path: string, values?: Values, check: boolean = false): TranslateResult | undefined {
		if (check && !this.$tke(path))
			return undefined

		return this.$t(this.$tp(path), values)
	}

	protected $tke(path: string): boolean {
		return this.$te(this.$tp(path))
	}
}
