import Vue from "vue"
import Notifications from "@/managers/session/notifications"
import Page from "@/managers/session/page"
import {getErrorLocation} from "@/utility/data/error"

const noneFatalErrors = ["aborterror"]
const noneFatalMessage = [
	"resizeobserver loop limit exceeded",
	"getBoundingClientRect",
	"directive intersect inserted hook",
	"the operation was aborted",
	"annulleret",
	"navigation cancelled from",
	"avoided redundant navigation to current location",
	"failed to load image",
	"this.$refs.content.focus",
	"reading 'focus'",
	"onFocusin",
	"this.$refs.content",
	"cannot read properties of undefined (reading 'contains')"]
const noneFatalErrorDetails = ["focus"]

Vue.config.errorHandler = (error, vm, info) => {
	reportError(`Unhandled Vue error in "${info}"`, error)
}

window.addEventListener("unhandledrejection", event => {
	const reason = event.reason ?? "unknown rejection"
	const isError = reason instanceof Error
	reportError("Unhandled promise rejection" + (isError ? "" : ": " + reason.toString()), isError ? reason : undefined)
	event.preventDefault()
})
window.addEventListener("error", event => {
	if (!(event.error instanceof Error))
		reportError("Unhandled error: " + event.message)
	else
		reportError("Unhandled error", event.error)

	event.preventDefault()
})

function reportError(message: string, error?: Error): void {
	if (warningCheck(message, error))
		Notifications.warning(message, error)
	else
		Notifications.error(message, error)
}

function warningCheck(message: string, error?: Error): boolean {
	return Page.isUnloaded
		|| (error && (
			noneFatalErrors.some(e => e === error.name.toLocaleLowerCase())
			|| noneFatalMessage.some(m => error.message.toLocaleLowerCase().includes(m))
			|| noneFatalErrorDetails.some(detail => detail === getErrorLocation(error).toLowerCase())
		)) || noneFatalMessage.some(m => message.toLocaleLowerCase().includes(m))
}
