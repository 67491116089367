import { Component, Vue } from "vue-property-decorator"
import Organizations, {Organization} from "@/managers/organization/organizations"
import type { Route } from "vue-router"
import {setInQuery, urlEncode} from "@/utility/data/url"

@Component
export default class MainOrganization extends Vue {
	protected get organization(): Organization {
		return Organizations.main!
	}

	protected get organizationUrl(): string {
		return "/admin/" + urlEncode(this.organization.key)
	}

	protected getOrganizationUrl(subPage: string, query?: Record<string, any>): string {
		return `${this.organizationUrl}/${setInQuery(subPage, query)}`
	}

	protected goToOrganizationUrl(subPage: string): Promise<Route | void> {
		return this.$navigateTo(this.getOrganizationUrl(subPage))
	}
}
