import "@mdi/font/css/materialdesignicons.css"
import "@/styles/styles.sass"
import Vue from "vue"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

const colors = {
	primary: "#2172fe",
	secondary: "#2d2f39",
	accent: "#e73540",
	error: "#e73540",
	info: "#2172fe",
	information: "#2172fe",
	success: "#12bf68",
	warning: "#ffbb01",
	white: "#ffffff",
	black: "#000000",
	red: "#e73540",
	green: "#12bf68",
	greenvibrant: "#27c32c",
	greenapple: "#4db837",
	darkgrey: "#637178",
	grey: "#818e94",
	graymedium: "#d8d8d8",
	graydarken: "#65676B",
	lightgrey: "#ebecee",
	checkboxbase: "#c4cdd5",
	background: "#ffffff",
	graylighter: "#f3f3f5",
	grayevenlighter: "#f5f5f5",
	whiteghost: "#f0f2f5",
	silver: "#ced0d4",
	bluelight: "#9fa3b0",
	bluedark: "#454c64",
	dodgerblue: "#315aff",
	yellow: "#ffbb01",
	yellowlight: "#ffeaba",
	redtinted: "#f5aeb3",
	midnight: "#0f1729",
	bluebright: "#006cfa",
	athensgray: "#f8f9fb",
	melrose: "#b7c6ff",
	shark: "#1a1d1f",
	seashell: "#eef0f5",
	azureblue: "#005fdc",
	graylightdarker: "#e3e6ef",
	jordyblue: "#7faeed"
}

export default new Vuetify({
	iconfont: "mdi",
	theme: {
		themes: {
			light: colors,
			dark: colors
		}
	}
})
