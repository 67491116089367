<template lang="pug">
	v-banner(v-model="isVisible" app sticky icon-color="warning" icon="mdi-wifi-strength-alert-outline").warning--text {{$tk('text')}}
		template(v-slot:actions="{ dismiss }")
			v-btn(text @click="dismiss" icon)
				v-icon mdi-close
</template>
<script lang="ts">
	import {Component, I18nComponentMixin} from "@/mixins/combinations"
	import Install from "@/managers/system/install"

	@Component({components: {}})
	export default class OfflineBanner extends I18nComponentMixin {
		private isDismissed = false

		private get isVisible(): boolean {
			return Install.isOffline && !this.isDismissed
		}

		private set isVisible(value: boolean) {
			if (!value)
				this.isDismissed = true
		}

		constructor() {
			super()
			this.i18nKey = "offlineBanner"
		}

		public created(): void {
			this.$watch(() => Install.isOffline, isOffline => {
				if (!isOffline)
					this.isDismissed = false
			}, {immediate: true})
		}
	}
</script>
