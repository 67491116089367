import { Component, Vue } from "vue-property-decorator"
import Preferences from "@/managers/system/preferences"
import CalendarType from "@/managers/data/preferences/calendarType"

@Component
export default class ClosableEditor extends Vue {
	protected get parentPage(): string {
		return this.$route.path.split("/")[2]
	}

	protected getCloseUrl(parameters?: Record<string, any>): string {
		if (this.parentPage === "calendar-grid") {
			const cols = Preferences.calendarType === CalendarType.grid4Day ? "4" : "7"

			if (parameters === undefined)
				parameters = {cols}
			else
				parameters.cols = cols
		}

		if (parameters !== undefined)
			Object.keys(parameters).forEach(key => {
				const value = parameters![key]
				if (value === undefined || value === null)
					delete parameters![key]
			})

		const queryString = new URLSearchParams(parameters).toString()

		return this.parentPage + (queryString !== "" ? "?" + queryString : "")
	}
}
