import type {DateTime} from "luxon"
import Vue from "vue"
import I18n from "./i18n"
import Notifications from "@/managers/session/notifications"
import Configuration from "@/managers/system/configuration"

class DateFormat {
	private lastLocale: string | null = null
	private formatters!: {[key: string]: (date: Date) => string}

	public install(vue: typeof Vue, options: any): void {
		vue.prototype.$df = (value: Date | DateTime, format: string) => {
			this.updateFormatters()

			if ("setZone" in value) {
				if (!value.isValid) {
					Notifications.warning("Trying to format invalid date")
					return ""
				}

				value = value.setZone("local", {keepLocalTime: true}).toJSDate()
			}

			if (this.formatters.hasOwnProperty(format))
				return this.formatters[format](value)

			Notifications.warning("Missing format: " + format)
			return value.toISOString()
		}
	}

	private updateFormatters(): void {
		if (I18n.locale === this.lastLocale)
			return

		this.lastLocale = I18n.locale

		const timeFormatter = new Intl.DateTimeFormat(
			"en-GB",
			{
				hour: valueFormats.digit,
				minute: valueFormats.digit,
				hour12: false,
			})

		const shortMonthDay = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				month: valueFormats.short,
				day: valueFormats.numeric
			})

		const numericMonthDay = new Intl.DateTimeFormat(
			"en-GB",
			{
				day: valueFormats.digit,
				month: valueFormats.digit
			})

		const numericMonthDayYear = new Intl.DateTimeFormat(
			"en-GB",
			{
				day: valueFormats.digit,
				month: valueFormats.digit,
				year: valueFormats.digit
			})

		const shortDate = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				year: valueFormats.numeric,
				month: valueFormats.short,
				day: valueFormats.numeric
			})

		const longDate = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				year: valueFormats.numeric,
				month: valueFormats.short,
				day: valueFormats.numeric
			})

		const month = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				month: valueFormats.long,
			})

		const monthDay = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				month: valueFormats.long,
				day: valueFormats.numeric
			})

		const monthYear = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				year: valueFormats.numeric,
				month: valueFormats.long
			})

		const dayOfMonth = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				day: valueFormats.numeric
			})

		const weekday = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				weekday: valueFormats.long
			})

		const shortWeekday = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				weekday: valueFormats.short
			})

		const veryShortDate = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				month: valueFormats.short,
				day: valueFormats.numeric
			})

		const longNoYear = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				month: valueFormats.long,
				day: valueFormats.numeric,
				weekday: valueFormats.long
			})

		const mediumNoYear = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				month: valueFormats.short,
				day: valueFormats.numeric,
				weekday: valueFormats.long
			})

		const shortNoYear = new Intl.DateTimeFormat(
			[this.lastLocale, Configuration.localization.fallback],
			{
				month: valueFormats.short,
				day: valueFormats.numeric,
				weekday: valueFormats.short
			})


		this.formatters = {
			time: timeFormatter.format,
			dateTime: date => `${shortMonthDay.format(date)} ${timeFormatter.format(date)}`,
			mediumDateTime: date => `${mediumNoYear.format(date)} ${timeFormatter.format(date)}`,
			numericDateTime: date => `${numericMonthDay.format(date)} ${timeFormatter.format(date)}`,
			numericDateYearTime: date => `${numericMonthDayYear.format(date)}, ${timeFormatter.format(date)}`,
			mediumNoYear: mediumNoYear.format,
			short: shortDate.format,
			month: month.format,
			monthDay: monthDay.format,
			numericMonthDay: numericMonthDay.format,
			numericMonthDayYear: numericMonthDayYear.format,
			monthYear: monthYear.format,
			dayOfMonth: dayOfMonth.format,
			weekday: weekday.format,
			shortWeekday: shortWeekday.format,
			veryShortDate: veryShortDate.format,
			longNoYear: longNoYear.format,
			longDate: longDate.format,
			shortNoYear: shortNoYear.format
		}
	}
}

Vue.use(new DateFormat())

enum valueFormats {
	numeric = "numeric",
	digit = "2-digit",
	long = "long",
	short = "short",
	narrow = "narrow"
}

declare module "vue/types/vue" {
	// tslint:disable-next-line
	interface Vue {
		$df: (value: Date | DateTime, format: string) => string
	}
}
