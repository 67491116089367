import {IServiceConnection, ServiceConnectionType} from "@chaosinsight/postoffice-portalclient"

export default class ServiceConnection {
	public id: string
	public type: ServiceConnectionType
	public serviceId: string
	public mustRenew: boolean
	public createdOn: Date
	public modifiedOn: Date

	public get isFacebook(): boolean {
		return this.type === ServiceConnectionType.facebook
	}

	constructor(id: string, typeId: ServiceConnectionType, serviceId: string, mustRenew: boolean, createdOn: Date, modifiedOn: Date) {
		this.id = id
		this.type = typeId
		this.serviceId = serviceId
		this.mustRenew = mustRenew
		this.createdOn = createdOn
		this.modifiedOn = modifiedOn
	}

	public static fromApi(data: IServiceConnection): ServiceConnection {
		return new ServiceConnection(
			data.Id,
			data.TypeId,
			data.ServiceId,
			data.MustRenew,
			new Date(data.CreatedOn),
			new Date(data.ModifiedOn))
	}
}

export {ServiceConnectionType}
