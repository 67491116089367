import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "../store"
import type {IStoreList} from "@/store/data/iStoreList"
import type PrivateServiceConnection from "@/managers/data/serviceConnection/privateServiceConnection"

@Module({dynamic: true, namespaced: true, name: "user/serviceConnections", store})
export class ServiceConnections extends VuexModule implements IStoreList<PrivateServiceConnection> {
	public list: PrivateServiceConnection[] | null = null
	public isAutomaticallyRenewing = false
	public hasAutomaticallyRenewed = false
	public isRemoving = false

	public get isReady(): boolean {
		return this.list !== null
	}

	@Mutation
	public setList(value: PrivateServiceConnection[] | null): void {
		this.list = value
	}

	@Mutation
	public setIsAutomaticallyRenewing(value: boolean): void {
		this.isAutomaticallyRenewing = value
	}

	@Mutation
	public setHasAutomaticallyRenewed(value: boolean): void {
		this.hasAutomaticallyRenewed = value
	}

	@Mutation
	public setIsRemoving(value: boolean): void {
		this.isRemoving = value
	}

	@Mutation
	public setConnectionRenewed(index: number): void {
		this.list![index].mustRenew = false
	}

	@Mutation
	public removeConnection(index: number): void {
		this.list!.splice(index, 1)
	}
}

export default getModule(ServiceConnections)
