import { Component, Inject, InjectReactive, Mixins, ModelSync, Prop, PropSync, Provide, ProvideReactive, Vue, Watch } from "vue-property-decorator"
import I18n from "./i18n"
import I18nView from "./i18nView"
import I18nComponent from "./i18nComponent"
import I18nViewComponent from "./i18nViewComponent"
import MainLocation from "./mainLocation"
import MainOrganization from "./mainOrganization"
import ClosableEditor from "./closableEditor"
import CurrencyComponent from "@/mixins/currencyComponent"
import TrackingComponent from "@/mixins/trackingComponent"

const I18nMixin = Mixins(I18n)
const I18nViewMixin = Mixins(I18nView)
const I18nComponentMixin = Mixins(I18nComponent)
const I18nViewComponentMixin = Mixins(I18nViewComponent)
const MainLocationMixin = Mixins(MainLocation)
const MainOrganizationMixin = Mixins(MainOrganization)
const CurrencyMixin = Mixins(CurrencyComponent)
const TrackingMixin = Mixins(TrackingComponent)

const I18nMainLocationMixin = Mixins(I18n, MainLocation)
const I18nMainOrganizationMixin = Mixins(I18n, MainOrganization)

const I18nComponentMainOrganizationCurrencyMixin = Mixins(I18nComponent, CurrencyComponent)

const I18nViewMainLocationMixin = Mixins(I18nView, MainLocation)
const I18nViewMainOrganizationMixin = Mixins(I18nView, MainOrganization)
const I18nViewMainLocationOrganizationMixin = Mixins(I18nView, MainLocation, MainOrganization)

const I18nComponentMainLocationMixin = Mixins(I18nComponent, MainLocation)
const I18nComponentMainOrganizationMixin = Mixins(I18nComponent, MainOrganization)
const I18nComponentMainLocationOrganizationMixin = Mixins(I18nComponent, MainLocation, MainOrganization)

const I18nViewComponentMainLocationMixin = Mixins(I18nViewComponent, MainLocation)
const I18nViewComponentMainOrganizationMixin = Mixins(I18nViewComponent, MainOrganization)
const I18nViewComponentMainLocationOrganizationMixin = Mixins(I18nViewComponent, MainLocation, MainOrganization)

const I18nViewMainLocationClosableEditorMixin = Mixins(I18nViewMainLocationMixin, ClosableEditor)
const I18nViewMainLocationCurrencyClosableEditorMixin = Mixins(I18nViewMainLocationMixin, CurrencyComponent, ClosableEditor)

const I18nViewTrackingMixin = Mixins(I18nViewComponent, TrackingMixin)
const I18nViewComponentTrackingMixin = Mixins(I18nViewComponentMixin, TrackingMixin)
const I18nViewMainLocationOrganizationTrackingMixin = Mixins(I18nView, MainLocation, MainOrganization, TrackingMixin)

export {Component, Inject, InjectReactive, Prop, PropSync, Provide, ProvideReactive, Mixins, ModelSync, Vue, Watch}

export {
	I18nMixin, I18nViewMixin, I18nComponentMixin, I18nViewComponentMixin, MainLocationMixin, MainOrganizationMixin, CurrencyMixin, TrackingComponent,
	I18nMainLocationMixin, I18nMainOrganizationMixin,
	I18nComponentMainOrganizationCurrencyMixin,
	I18nViewMainLocationMixin, I18nViewMainOrganizationMixin, I18nViewMainLocationOrganizationMixin,
	I18nComponentMainLocationMixin, I18nComponentMainOrganizationMixin, I18nComponentMainLocationOrganizationMixin,
	I18nViewComponentMainLocationMixin, I18nViewComponentMainOrganizationMixin,	I18nViewComponentMainLocationOrganizationMixin,
	I18nViewMainLocationClosableEditorMixin, I18nViewMainLocationCurrencyClosableEditorMixin,
	I18nViewTrackingMixin, I18nViewComponentTrackingMixin, I18nViewMainLocationOrganizationTrackingMixin
}
