import State from "@/store/authentication/savedLogin"
import Notifications from "@/managers/session/notifications"
import Portal from "@/managers/portal"
import IAuthenticationMethod, {AuthenticationMethodState} from "@/store/authentication/iauthenticationMethod"
import {localStorage} from "@/managers/system/fallbackStorage"
import Page from "@/managers/session/page"

class SavedLogin implements IAuthenticationMethod {
	private static readonly idKey = "SecureCookieId"
	private static readonly passwordKey = "SecureCookiePassword"

	public get currentState(): AuthenticationMethodState {
		return State.currentState
	}

	public get canLogin(): boolean {
		return State.canLogin
	}

	public get isLoggingIn(): boolean {
		return State.isLoggingIn
	}

	constructor() {
		const data = SavedLogin.getData()

		State.setState(data !== null ? AuthenticationMethodState.CanLogin : AuthenticationMethodState.CannotLogin)
	}

	public async login(): Promise<void> {
		if (!this.canLogin)
			throw new Error("Can't login now")

		const data = SavedLogin.getData()

		if (data === null)
			throw new Error("No saved login")

		State.setState(AuthenticationMethodState.LoggingIn)
		try {
			const response = await Portal.call.sessions.secureCookieLogin(data.id, data.password).response
			SavedLogin.setData(response.SecureCookie.Id, response.SecureCookie.Password)
			State.setState(AuthenticationMethodState.CanLogin)
		} catch (error: any) {
			this.clear()
			throw error
		}
	}

	public save(): void {
		const data = SavedLogin.getData()

		if (data !== null)
			return

		Portal.call.sessions.secureCookiePost()
			.response
			.then(
				response => {
					SavedLogin.setData(response.Id, response.Password)
					State.setState(AuthenticationMethodState.CanLogin)
				},
				message => Notifications.warning(`Failed to create secure cookie: ${message}`))
	}

	public clear(): void {
		if (!Page.isUnloaded)
			SavedLogin.clearData()
		State.setState(AuthenticationMethodState.CannotLogin)
	}

	private static getData(): {id: string, password: string} | null {
		const id = localStorage.getItem(SavedLogin.idKey)
		const password = localStorage.getItem(SavedLogin.passwordKey)

		return id !== null && password !== null
			? {id, password}
			: null
	}

	private static setData(id: string, password: string): void {
		localStorage.setItem(SavedLogin.idKey, id)
		localStorage.setItem(SavedLogin.passwordKey, password)
	}

	private static clearData(): void {
		localStorage.removeItem(SavedLogin.idKey)
		localStorage.removeItem(SavedLogin.passwordKey)
	}
}

export default new SavedLogin()
