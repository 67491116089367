import {ServiceConnectionType} from "@chaosinsight/postoffice-portalclient"
import Authentication from "@/managers/facebook/authentication"
import Notifications from "@/managers/session/notifications"
import {watch} from "@/store/store"
import State from "@/store/facebook/accounts"
import FacebookAccount from "@/managers/data/facebook/facebookAccount"
import {call} from "@/managers/facebook/loader"
import Permissions from "@/managers/facebook/permissions"
import {firstOrNull, toItemMap} from "@/utility/structure/array"

class Accounts {
	public get isReady(): boolean {
		return this.state.isReady
	}

	public get list(): FacebookAccount[] | null {
		return this.state.list
	}

	public get isUpdating(): boolean {
		return this.state.updater !== null
	}

	public get canLoad(): boolean {
		return Authentication.isLoggedIn
	}

	public get lastUpdateFailed(): boolean {
		return this.state.lastUpdateFailed
	}

	private get state(): typeof State {
		return State
	}

	constructor() {
		watch(
			() => [Permissions.hasAllPagePermissions, Permissions.hasAllInstagramPermissions],
			() => {
				if (Authentication.authResponse !== null)
					this.updatePages()
						.catch(reason => Notifications.warning("Failed to update Facebook pages after login", reason))
				else
					this.state.setList(null)
			}, {immediate: true})
	}

	public async updatePages(): Promise<void> {
		if (!Authentication.isLoggedIn || !Permissions.hasCheckedPermissions) {
			this.state.setList(null)
			return
		}

		if (this.isUpdating)
			return this.state.updater!

		const updater = this.getAndMergePages()
			.then(
				pages => {
					if (Authentication.isLoggedIn) {
						pages.sort((a1, a2) => a1.sortName.localeCompare(a2.sortName))
						this.state.setList(pages)
					} else
						this.state.setLastUpdateFailed(false)
				},
				reason => {
					this.state.setLastUpdateFailed(true)
					throw reason
				})
			.finally(() => this.state.setUpdater(null))

		this.state.setUpdater(updater)

		return updater
	}

	public isAccount(account: FacebookAccount, id: string, type: ServiceConnectionType): boolean {
		return type === ServiceConnectionType.facebook
			? account.id === id
			: account.instagramAccount?.id === id
	}

	public getById(id: string, type: ServiceConnectionType): FacebookAccount | null {
		if (this.list === null)
			return null
		return firstOrNull(this.list, account => this.isAccount(account, id, type))
	}

	private getAndMergePages(): Promise<FacebookAccount[]> {
		return Promise.all(
			[
				this.getAllPages(false),
				Permissions.hasAllInstagramPermissions ? this.getAllPages(true) : Promise.resolve([])
			])
			.then(pages => {
				const instagramMap = toItemMap(pages[1])
				return pages[0].map(p => instagramMap.get(p.id) ?? p)
			})
	}

	private getAllPages(includeInstagram: boolean): Promise<FacebookAccount[]> {
		return new Promise<FacebookAccount[]>((resolve, reject) => {
			const result: FacebookAccount[] = []

			const handleResponse = (responsePromise: Promise<IFacebookAccountResponse>) => {
				responsePromise.then(response => {
					result.push(...response.data.map(data => FacebookAccount.fromSdk(data)))

					if (!response.paging || response.data.length === 0 || response.data.length === response.summary.total_count)
						resolve(result)
					else
						handleResponse(this.callGetPages(response.paging.cursors.after, includeInstagram))
				}, reject)
			}
			handleResponse(this.callGetPages(null, includeInstagram))
		})
	}

	private callGetPages(after: string | null, includeInstagram: boolean): Promise<IFacebookAccountResponse> {
		const parameters: any = {summary: "total_count", fields: ["id", "name", "tasks", "location"]}
		if (includeInstagram)
			parameters.fields.push("instagram_business_account{username}")
		if (after !== null)
			parameters.after = after

		return call<IFacebookAccountResponse>("me/accounts", parameters)
	}
}
export {FacebookAccount, ServiceConnectionType}
export default new Accounts()
