import type FacebookPage from "@/managers/data/facebook/facebookPage"
import Accounts, {ServiceConnectionType} from "@/managers/facebook/accounts"
import {when} from "@/store/store"
import {firstOrNull} from "@/utility/structure/array"

class Pages {
	public get canGet(): boolean {
		return Accounts.canLoad
	}

	public getImage(id: string): string {
		return `https://graph.facebook.com/${id}/picture?type=normal`
	}

	public getPage(id: string): Promise<FacebookPage> {
		return this.getPageByType(id, ServiceConnectionType.facebook)
	}

	public getPageByInstagram(id: string): Promise<FacebookPage> {
		return this.getPageByType(id, ServiceConnectionType.instagram)
	}

	public async getPageByType(id: string, type: ServiceConnectionType): Promise<FacebookPage> {
		await when(() => Accounts.isReady || !Accounts.isUpdating)

		if (Accounts.isReady) {
			const account = firstOrNull(Accounts.list!, a => Accounts.isAccount(a, id, type))
			if (account !== null)
				return account
		}

		throw new Error("Failed to get page")
	}
}

export default new Pages()
