import Component from "vue-class-component"
import I18n from "@/mixins/i18n"
import Notifications from "@/managers/session/notifications"

@Component
export default class I18nView extends I18n {
	private isLeaving = false

	protected get i18nViewKey(): string | null {
		const hierarchy: string[] = []

		for (const matched of this.$route.matched) {
			if (matched.meta.i18n === undefined) {
				Notifications.warning("I18n key is not defined on matched view: " + matched.path)
				return null
			}

			hierarchy.push(matched.meta.i18n)
			if (matched.instances.default === this)
				return `views.${hierarchy.join(".")}.`
		}
		if (!this.isLeaving)
			Notifications.warning("Current view was not matched: " + this.$options.name)
		return null
	}

	protected get i18nKey(): string | null {
		return this.i18nViewKey
	}
	protected set i18nKey(value: string | null) {
		throw new Error("i18nKey can not be set on a I18nView")
	}

	public beforeRouteLeave(to: any, from: any, next: () => void): void {
		this.isLeaving = true
		next()
	}
}
