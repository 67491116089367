import State from "@/store/user/user"
import PrivateUser from "@/managers/data/user/privateUser"
import Authentication from "@/managers/authentication/authentication"
import Notifications from "@/managers/session/notifications"
import Portal from "@/managers/portal"
import Configuration from "@/managers/system/configuration"
import Tracking from "@/managers/session/logging/tracking"
import {globalEvents, sendGlobalEvent} from "@/managers/session/globalEvents"

class User {
	public get isReady(): boolean {
		return State.isReady
	}

	public get current(): PrivateUser | null {
		return State.current
	}

	public get hasAcceptedTermsOfUse(): boolean | null {
		return this.current !== null ? this.current.termsOfUseConsent === Configuration.documents.termsVersion : null
	}

	public get canTrack(): boolean {
		return this.current !== null && this.current.termsOfUseConsent !== null
	}

	constructor() {
		Authentication.watchIsAuthenticated(isAuthenticated => this.update(isAuthenticated), true)
	}

	public async acceptTermsOfUse(): Promise<void> {
		if (!this.isReady)
			throw new Error("User is not ready")

		await Portal.call.users.put(undefined, undefined, true).response
		State.modifyCurrent({termsOfUseConsent: Configuration.documents.termsVersion})
		Tracking.event("UserSettings", "Accept terms of use")
	}

	public async setLanguage(language: string): Promise<void> {
		if (!this.isReady)
			throw new Error("User is not ready")

		if (this.current!.language !== language) {
			await Portal.call.users.put(undefined, language).response
			State.modifyCurrent({language})
			Tracking.event("UserSettings", "Change language", language)
		} else
			Tracking.event("UserSettings", "Language", language, true)
	}

	public async setName(name: string): Promise<void> {
		if (!this.isReady)
			throw new Error("User is not ready")

		if (this.current!.name !== name) {
			await Portal.call.users.put(name).response
			State.modifyCurrent({name})
			sendGlobalEvent(globalEvents.userChanged, this.current)
			Tracking.event("UserSettings", "Change name")
		}
	}

	public async setNotificationConsent(consent: boolean): Promise<void> {
		if (!this.isReady)
			throw new Error("User is not ready")

		await Portal.call.users.put(undefined, undefined, undefined, undefined, consent).response
		State.modifyCurrent({notificationConsent: consent})
		Tracking.event("UserSettings", "Change notifications", consent.toString())
	}

	public async setUiVersion(uiVersion: string): Promise<void> {
		if (!this.isReady)
			throw new Error("User is not ready")

		await Portal.call.users.put(undefined, undefined, undefined, undefined, undefined, uiVersion).response
		State.modifyCurrent({uiVersion})
	}

	public async setUsernamePassword(username?: string, newPassword?: string, currentPassword?: string): Promise<void> {
		if (!this.isReady)
			throw new Error("User is not ready")

		await Portal.call.users.mePasswordsPut(username ?? null, newPassword ?? null, currentPassword ?? null).response
		State.modifyCurrent({username, emailAddress: username, hasPassword: newPassword !== undefined ? true : undefined})
		sendGlobalEvent(globalEvents.userChanged, this.current)
	}

	public async modifyUsername(username: string, password: string): Promise<void> {
		if (!this.isReady)
			throw new Error("User is not ready")

		await Portal.call.users.mePatch(username, password).response
		State.modifyCurrent({username})
		sendGlobalEvent(globalEvents.userChanged, this.current)
	}

	public async deleteUser(): Promise<void> {
		if (!Authentication.isAuthenticated)
			throw new Error("Can't delete unauthenticated user")

		await Portal.call.users.delete().response
		Tracking.event("User", "Deleted")
		await Authentication.logOut()
	}

	private update(isAuthenticated: boolean): void {
		if (isAuthenticated)
			Portal.call.users.me().response
				.then(user => State.setCurrent(PrivateUser.fromApi(user)))
				.catch(reason => Notifications.error("Failed to get current user: " + reason.message))
		else
			State.setCurrent(null)
	}
}

export {PrivateUser}
export default new User()
