import { getModule, Action, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store/store"

@Module({dynamic: true, namespaced: true, name: "facebook/authentication", store})
class Authentication extends VuexModule {
	public isLoggingIn = false
	public hasCheckedLogin = false
	public hasCheckedPermissions = false
	public hasPermissionsChanged = false

	public isCookieBlocked = false
	public hasModuleFailed = false
	public hasHandledInitialUnknown = false

	public authResponse: IFacebookAuthResponse | null = null
	public grantedPermissions: string[] = []

	public get isLoggedIn(): boolean {
		return this.authResponse !== null
	}

	public get canLogin(): boolean {
		return !this.isLoggingIn && this.hasCheckedLogin && !this.isLoggedIn && !this.hasModuleFailed
	}

	@Mutation
	public setIsLoggingIn(value: boolean): void {
		this.isLoggingIn = value
	}

	@Mutation
	public setHasCheckedLogin(value: boolean): void {
		this.hasCheckedLogin = value
	}

	@Mutation
	public setHasCheckedPermissions(value: boolean): void {
		this.hasCheckedPermissions = value
	}

	@Mutation
	public setHasPermissionsChanged(value: boolean): void {
		this.hasPermissionsChanged = value
	}

	@Mutation
	public setIsCookieBlocked(value: boolean): void {
		this.isCookieBlocked = value
	}

	@Mutation
	public setHasModuleFailed(value: boolean): void {
		this.hasModuleFailed = value
	}

	@Mutation
	public setHasHandledInitialUnknown(value: boolean): void {
		this.hasHandledInitialUnknown = value
	}

	@Mutation
	public setAuthResponse(value: IFacebookAuthResponse | null): void {
		this.authResponse = value
	}

	@Mutation
	public setGrantedPermissions(value: string[]): void {
		this.grantedPermissions = value
	}

	@Action
	public async authResponseChanged(response: IFacebookUserAuthenticate): Promise<void> {
		if (response.status === "connected") {
			const auth = response.authResponse!
			this.context.commit("setAuthResponse", auth)

			if (auth.grantedScopes)
				await this.context.dispatch("updatePermissions", auth.grantedScopes.split(","))
		} else {
			this.context.commit("setGrantedPermissions", [])
			this.context.commit("setHasCheckedPermissions", false)
			this.context.commit("setHasPermissionsChanged", false)
			this.context.commit("setAuthResponse", null)
		}

		this.context.commit("setIsCookieBlocked", response.status === "unknown")
		this.context.commit("setIsLoggingIn", false)
		if (!this.hasCheckedLogin)
			this.context.commit("setHasCheckedLogin", true)
	}

	@Action
	public async updatePermissions(value: string[]): Promise<void> {
		if (this.hasCheckedPermissions && (this.grantedPermissions.length !== value.length || !this.grantedPermissions.every(p => value.includes(p))))
			this.context.commit("setHasPermissionsChanged", true)

		this.context.commit("setGrantedPermissions", value)
		this.context.commit("setHasCheckedPermissions", true)
	}
}

export default getModule(Authentication)
