import {getModule, Module, Mutation, VuexModule} from "vuex-module-decorators"
import store from "../store"
import type PublicServiceConnection from "@/managers/data/serviceConnection/publicServiceConnection"
import type {IStoreListEditable} from "@/store/data/iStoreList"
import type DetailedService from "@/managers/data/serviceConnection/detailedService"

@Module({dynamic: true, namespaced: true, name: "location/services", store})
class Services extends VuexModule implements IStoreListEditable<DetailedService> {
	public list: DetailedService[] | null = null

	public get isReady(): boolean {
		return this.list !== null
	}

	@Mutation
	public setList(value: DetailedService[] | null): void {
		this.list = value
	}

	@Mutation
	public add(value: DetailedService): void {
		this.list!.push(value)
	}

	@Mutation
	public remove(index: number): void {
		this.list!.splice(index, 1)
	}

	@Mutation
	public addConnection(value: {serviceIndex: number, connection: PublicServiceConnection}): void {
		this.list![value.serviceIndex].connections.push(value.connection)
	}

	@Mutation
	public removeConnection(value: {serviceIndex: number, connectionIndex: number}): void {
		this.list![value.serviceIndex].connections.splice(value.connectionIndex, 1)
	}
}

export default getModule(Services)
