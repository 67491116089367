import { getModule, Action, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "../store"

@Module({dynamic: true, namespaced: true, name: "session/routing", store})
export class Routing extends VuexModule {
	public routingStatus: string | null = null

	public get isRouting(): boolean {
		return this.routingStatus !== null
	}

	@Mutation
	public setRoutingStatus(value: string | null): void {
		this.routingStatus = value
	}

	@Action
	public routeComplete(): void {
		this.context.commit("setRoutingStatus", null)
	}
}

export default getModule(Routing)
