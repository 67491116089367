import PortalClient, {hasServiceErrorCode, ErrorCode, ExtensionHandler, IServiceError, ServiceError} from "@chaosinsight/portalclient"
import {PostOfficeErrorCode} from "@chaosinsight/postoffice-portalclient"
import Configuration from "@/managers/system/configuration"
import Notifications from "@/managers/session/notifications"

class Portal {
	public sessionExpiredHandler: (() => Promise<boolean>) | null = null
	private readonly _client!: PortalClient // tslint:disable-line

	public get client(): PortalClient {
		return this._client
	}

	public get call(): ExtensionHandler {
		return this._client.call
	}

	public constructor() {
		this._client = new PortalClient(Configuration.connections.portalPath, "7", error => this.handleError(error))
	}

	public clearSession(): void {
		Notifications.debug("Clearing Portal session")
		this.client.updateSession(null)
	}

	private handleError(error: IServiceError): Promise<boolean> {
		switch (error.Code) {
			case PostOfficeErrorCode.sessionNotAuthenticated:
				if (this.sessionExpiredHandler !== null) {
					Notifications.debug("Handling error " + error.Code + ": " + error.Message)
					return this.sessionExpiredHandler()
				}
				break
		}
		return Promise.resolve(false)
	}
}

export {ErrorCode, hasServiceErrorCode, PostOfficeErrorCode, ServiceError}
export default new Portal()
