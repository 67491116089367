import {DateTime as LuxDateTime, SystemZone} from "luxon"
import Localization from "@/managers/session/localization"
import SelectOption from "@/components/structure/selectOption"

class DateTime {
	public readonly maxDate = 1_000_000_000_000
	public readonly shortDateFormat = "yyMMdd"
	private currentTimeZoneGuess: string | null = null
	private cachedPrettyTimeZones: Array<SelectOption<string>> | null = null
	private cachedSimpleTimeZoneNames: Map<string, string> | null = null
	private cachedSystemTimeZone:  SystemZone | null = null

	public get systemTimeZone(): SystemZone {
		if (this.cachedSystemTimeZone === null)
			this.cachedSystemTimeZone = new SystemZone()

		return this.cachedSystemTimeZone
	}

	public get timeZones(): string[] {
		const asAny = Intl as any
		if (asAny && asAny.supportedValuesOf)
			return asAny.supportedValuesOf("timeZone")

		const fallbackZones = ["America/Los_Angeles", "America/New_York", "Asia/Tokyo", "Europe/Berlin", "Europe/Copenhagen", "Europe/London", "Europe/Oslo", "Europe/Paris", "Europe/Stockholm"]

		if (!fallbackZones.includes(this.localTimeZone)) {
			fallbackZones.push(this.localTimeZone)
			fallbackZones.sort()
		}

		return fallbackZones
	}

	public get prettyTimeZones(): Array<SelectOption<string>>  {
		if (this.cachedPrettyTimeZones === null)
			this.cachedPrettyTimeZones = this.timeZones.map(t => new SelectOption(t, t.replaceAll("_", " ").replaceAll("/", " / ")))

		return this.cachedPrettyTimeZones!
	}

	public get localTimeZone(): string {
		if (this.currentTimeZoneGuess === null)
			this.currentTimeZoneGuess = LuxDateTime.local().zoneName
		return this.currentTimeZoneGuess
	}

	private get simpleTimeZoneNames(): Map<string, string> {
		if (this.cachedSimpleTimeZoneNames === null)
			this.cachedSimpleTimeZoneNames = new Map(this.timeZones.map(tz => {
				const split = tz.split("/")
				return [tz, split[split.length - 1].replace("_", " ")]
			}))

		return this.cachedSimpleTimeZoneNames
	}

	public toSimpleTimeZoneName(name: string): string {
		return this.simpleTimeZoneNames.get(name) ?? name
	}

	public getSimpleTimeZoneNameIfNeeded(date: LuxDateTime): string {
		return date.zoneName !== "UTC" && date.offset !== this.systemTimeZone.offset(date.toMillis())
			?` (${this.toSimpleTimeZoneName(date.zoneName!)})`
			: ""
	}

	public fromLocalTimeZone(date: Date, timeZone: string): Date {
		return LuxDateTime.fromJSDate(date, {zone: "local"}).setZone(timeZone, {keepLocalTime: true}).toJSDate()
	}

	public toLocalTimeZone(date: Date, timeZone: string): Date {
		return LuxDateTime.fromJSDate(date, {zone: timeZone}).setZone("local", {keepLocalTime: true}).toJSDate()
	}

	public asUTC(date: Date): Date {
		return LuxDateTime.fromJSDate(date).setZone("utc", {keepLocalTime: true}).toJSDate()
	}

	public getWeekNumber(date: Date): number {
		return LuxDateTime.fromJSDate(date).weekNumber
	}

	public format(date: Date, format: string): string {
		return LuxDateTime.fromJSDate(date).toFormat(format)
	}
	public parse(value: string, format: string): Date {
		return LuxDateTime.fromFormat(value, format).toJSDate()
	}

	public toIsoString(date: Date, includeZ: boolean = true): string {
		return date.toISOString().slice(0, -5) + (includeZ ? "Z" : "")
	}

	public toIsoDateString(date: Date): string {
		return date.toJSON().slice(0, 10)
	}

	public toShortDateString(date: Date): string {
		return this.format(date, this.shortDateFormat)
	}
	public fromShortDateString(value: string): Date {
		return this.parse(value, this.shortDateFormat)
	}

	public toServerString(value: Date): string {
		const dateString = this.toIsoString(value, false)
		return dateString.replace("T", " ")
	}
	public fromServerString(value: string): Date {
		return new Date(value.replace(" ", "T") + "Z")
	}

	public cloneWithOutTime(date: Date): Date {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate())
	}

	public cloneAsWeekStart(date: Date, add: number = 0): Date {
		const day = date.getDay()

		return new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate() - (day === 0 ? 6 : (day - 1)) + add * 7
		)
	}

	public cloneAsDayStart(date: Date, add: number = 0): Date {
		return new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate() + add
		)
	}

	public cloneAsDayEnd(date: Date, add: number = 0): Date {
		return new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate() + 1 + add,
			0,
			0,
			-1
		)
	}

	public cloneAndAdd(date: Date, days: number = 0, months: number = 0): Date {
		return new Date(
			date.getFullYear(),
			date.getMonth() + months,
			date.getDate() + days,
			date.getHours(),
			date.getMinutes(),
			date.getSeconds(),
			date.getMilliseconds()
		)
	}

	public isInWeek(date: Date, weekStart: Date): boolean {
		const dateTime = date.getTime()
		const weekStartTime = weekStart.getTime()

		if (dateTime < weekStartTime)
			return false

		const weekEnd = new Date(weekStartTime)

		return dateTime < weekEnd.setDate(weekEnd.getDate() + 7)
	}

	public resetTime(date: Date): Date {
		date.setHours(0, 0, 0, 0)

		return date
	}

	public isWeekend(date: Date): boolean {
		return date.getDay() % 6 === 0
	}

	public isSameDay(date1: Date, date2: Date): boolean {
		return (
			date1.getFullYear() === date2.getFullYear() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getDate() === date2.getDate()
		)
	}

	public isSameDayUTC(date1: Date, date2: Date): boolean {
		return (
			date1.getUTCFullYear() === date2.getUTCFullYear() &&
			date1.getUTCMonth() === date2.getUTCMonth() &&
			date1.getUTCDate() === date2.getUTCDate()
		)
	}

	public getNumberOfDays(from: Date, to: Date): number {
		const day = 1000 * 60 * 60 * 24
		return Math.round((to.getTime() - from.getTime()) / day)
	}

	public getNextRoundDateTime(minuteInterval: number = 5): LuxDateTime {
		return this.roundToMinuteInterval(LuxDateTime.now(), minuteInterval)
	}

	public roundToMinuteInterval(date: LuxDateTime, minuteInterval: number = 5): LuxDateTime {
		return date.set({minute: Math.ceil(date.minute / minuteInterval) * minuteInterval, second: 0, millisecond: 0})
	}

	public getNextRound(minuteInterval: number = 5): Date {
		const date = new Date()
		const min = date.getMinutes()
		date.setMinutes(Math.ceil(min / minuteInterval) * minuteInterval, 0, 0)
		return date
	}

	public fromNow(date: Date): string {
		return LuxDateTime.fromJSDate(date).setLocale(Localization.current).toRelative({padding: 800})!
	}

	public fromNowDateTime(date: LuxDateTime): string {
		return date.setLocale(Localization.current).toRelative({padding: 800})!
	}

	public nowAdd(days: number, seconds: number = 0): Date {
		const date = new Date()
		date.setDate(date.getDate() + days)
		date.setSeconds(date.getSeconds() + seconds, 0)
		return date
	}

	public fromDate(from: Date, days: number): Date {
		const date = new Date(from)
		date.setDate(date.getDate() + days)
		return date
	}

	public isInRange(value: Date, from: Date | null, to: Date | null): boolean {
		const valueTime = value.getTime()

		return (from === null || from.getTime() <= valueTime) && (to === null || to.getTime() >= valueTime)
	}

	public sort(date1: Date | null, date2: Date | null): number {
		if (date1 === null || date2 === null)
			if (date1 !== null)
				return 1
			else if (date2 !== null)
				return -1
			else
				return 0

		return date1.getTime() - date2.getTime()
	}

	public min(...dates: Array<Date | null>): Date | null {
		let result = null
		let value = Number.MAX_VALUE

		for (const date of dates) {
			if (date === null)
				continue

			const dateValue = date.getTime()
			if (dateValue < value) {
				result = date
				value = dateValue
			}
		}

		return result
	}

	public max(...dates: Array<Date | null>): Date | null {
		let result = null
		let value = Number.MIN_VALUE

		for (const date of dates) {
			if (date === null)
				continue

			const dateValue = date.getTime()
			if (dateValue > value) {
				result = date
				value = dateValue
			}
		}

		return result
	}

	public parseStringOrNull(value: string | null): Date | null {
		return value !== null ? new Date(value) : null
	}
}

export default new DateTime()
