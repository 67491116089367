import {Notification, NotificationType} from "@/managers/data/session/notification"
import State from "@/store/session/notifications"
import Log from "@/managers/session/logging/log"
import Page from "@/managers/session/page"

// tslint:disable:no-console
class Notifications {
	public get list(): Notification[] {
		return State.list
	}

	public information(message: string): void {
		console.info(message)
		Log.informationNotification(message)
		this.addNotification(Notification.information(message))
	}

	public error(message: string, error?: Error): void {
		Page.callIfNotUnloaded(() => {
			if (error !== undefined)
				console.error(message, error)
			else
				console.error(message)

			const combinedMessage = message + (error !== undefined ? ": " + Notifications.getErrorMessage(error) : "")
			Log.error(message, error)
			this.addNotification(Notification.error(combinedMessage))
		})
	}

	public errorNoneTracking(title: string, message: string): void {
		this.addNotification(Notification.error(message, title))
	}

	public warning(message: string, error?: Error): void {
		Page.callIfNotUnloaded(() => {
			if (error !== undefined)
				console.warn(message, error)
			else
				console.warn(message)

			const combinedMessage = message + (error !== undefined ? ": " + Notifications.getErrorMessage(error) : "")
			Log.error(message, error, false)
			this.addNotification(Notification.warning(combinedMessage))
		})
	}

	public debug(message: string): void {
		console.debug(message)
		this.addNotification(Notification.debug(message))
	}

	private addNotification(notification: Notification): Notification {
		setTimeout(() => State.add(notification), 0) // Prevent dependencies in vue code
		return notification
	}

	private static getErrorMessage(error: Error): string {
		return error.message
			? error.message
			: error.name
				? error.name
				: "Unknown error"
	}
}

export {Notification, NotificationType}
export default new Notifications()
