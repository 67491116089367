import Component from "vue-class-component"
import I18n from "@/mixins/i18n"

@Component
export default class I18nViewComponent extends I18n {
	protected get nearestViewI18nKey(): string | null {
		let parent: any = this.$parent

		while (parent !== undefined && parent !== this.$root) {
			if (parent.i18nViewKey !== undefined)
				return parent.i18nViewKey

			parent = parent.$parent
		}
		return null
	}

	protected get i18nKey(): string | null {
		const viewKey = this.nearestViewI18nKey
		const innerKey = this.innerI18nKey

		if (viewKey === null || innerKey === null)
			return null

		return viewKey + innerKey
	}

	protected set i18nKey(value: string | null) {
		this.innerI18nKey = value
	}
}
