import Authentication from "@/managers/authentication/authentication"
import {when} from "@/store/store"
import ListModifiable from "@/managers/base/listModifiable"
import type {IItemKeyed} from "@/managers/data/base/iItem"
import type {IStoreListModifiable, IStoreMain} from "@/store/data/iStoreList"

export default abstract class ListMain<T extends IItemKeyed> extends ListModifiable<T, true> {
	public get main(): T | null {
		const id = this.state.mainId
		return id !== null ? this.getById(id) : null
	}

	public set main(value: T | null) {
		if (value === null) {
			if (this.state.mainId === null)
				return
			this.state.setMainId(null)
		} else {
			if (this.state.mainId === value.id)
				return
			this.state.setMainId(value.id)
		}
	}

	protected abstract get state(): IStoreListModifiable<T> & IStoreMain<T>

	protected get dependent(): true | null {
		return Authentication.isAuthenticated ? true : null
	}

	public getByKey(key: string): T | null {
		for (const item of this.list!)
			if (item.key === key)
				return item

		return null
	}

	public async getByKeyWhenReady(key: string): Promise<T> {
		await when(() => this.isReady)

		const item = this.getByKey(key)

		if (item === null)
			throw new Error(`${this.itemName} with key: ${key} not found`)
		return item
	}

	protected reset(): void {
		this.main = null
		super.reset()
	}
}

export {IStoreListModifiable, IStoreMain}
