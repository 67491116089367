import {watch} from "@/store/store"
import State from "@/store/authentication/facebookPortal"
import FacebookAuthentication from "@/managers/facebook/authentication"
import IAuthenticationMethod, {AuthenticationMethodState} from "@/store/authentication/iauthenticationMethod"
import Portal, {hasServiceErrorCode, PostOfficeErrorCode} from "@/managers/portal"

class Facebook implements IAuthenticationMethod {
	public get currentState(): AuthenticationMethodState {
		return State.currentState
	}

	public get canLogin(): boolean {
		return State.canLogin
	}

	public get isLoggingIn(): boolean {
		return State.isLoggingIn
	}

	constructor() {
		watch(() => FacebookAuthentication.isLoggedIn, isLoggedIn => State.setState(isLoggedIn ? AuthenticationMethodState.CanLogin : AuthenticationMethodState.CannotLogin), {immediate: true})
	}

	public async login(): Promise<void> {
		if (!this.canLogin)
			throw new Error("Can't login now")

		if (FacebookAuthentication.authResponse === null)
			throw new Error("authResponse is null")

		State.setState(AuthenticationMethodState.LoggingIn)
		try {
			await Portal.call.sessions.facebookLogin(FacebookAuthentication.authResponse.accessToken).response
		} catch (error: any) {
			if (this.isUserNotKnownError(error))
				await FacebookAuthentication.logOut()
			throw error
		} finally {
			State.setState(FacebookAuthentication.isLoggedIn ? AuthenticationMethodState.CanLogin : AuthenticationMethodState.CannotLogin)
		}
	}

	public isUserNotKnownError(error: Error): boolean {
		return hasServiceErrorCode(error, PostOfficeErrorCode.facebookUserNotFound)
	}
}

export default new Facebook()
