import FacebookPage from "@/managers/data/facebook/facebookPage"
import InstagramBusinessAccount from "@/managers/data/instagram/InstagramBusinessAccount"

export default class FacebookAccount extends FacebookPage {
	public static readonly requiredTasks = ["ANALYZE", "CREATE_CONTENT", "MODERATE", "MESSAGING"]

	public tasks?: string[]
	public accessToken: string

	public get hasRequiredTasks(): boolean {
		return this.missingTasks.length === 0
	}

	public get areTasksUnknown(): boolean {
		return this.tasks === undefined
	}

	public get missingTasks(): string[] {
		return this.areTasksUnknown ? [] : FacebookAccount.requiredTasks.filter(t => this.tasks!.indexOf(t) === -1)
	}

	constructor(id: string, name: string, tasks: string[] | undefined, accessToken: string, city: string | null, street: string | null, instagramAccount: InstagramBusinessAccount | null) {
		super(id, name, city, street, instagramAccount)
		this.tasks = tasks
		this.accessToken = accessToken
	}

	public static fromSdk(data: IFacebookAccount): FacebookAccount {
		return new FacebookAccount(
			data.id,
			data.name,
			data.tasks,
			data.access_token,
			data.location?.city ?? null,
			data.location?.street ?? null,
			data.instagram_business_account ? InstagramBusinessAccount.fromSdk(data.instagram_business_account) : null)
	}
}
