const cleanCharactersMask = /[^a-z0-9\-_]/g
const noEmptyEqualsMask = /=(?=$|&)/g

export function setInQuery(url: string, parameters?: Record<string, any>): string {
	if (!parameters)
		return url

	const urlSplit = url.split("?")

	const query = new URLSearchParams(
		urlSplit.length > 1
			? urlSplit[1]
			: undefined)

	Object.entries(parameters).forEach(([key, value]) => {
		if (value === null)
			query.set(key, "")
		else if (value !== undefined)
			query.set(key, value.toString())
	})

	const queryString = query.toString().replaceAll(noEmptyEqualsMask, "")

	return queryString !== ""
		? `${urlSplit[0]}?${queryString}`
		: urlSplit[0]
}

export function toUrlCleanString(value: string): string {
	return value.toLocaleLowerCase().replaceAll(cleanCharactersMask, "")
}

export function urlEncode(url: string): string {
	return encodeURIComponent(url)
}
