import type IItem from "@/managers/data/base/iItem"

export function flatten<T>(array: T[][]): T[] {
	return array.reduce((acc, val) => acc.concat(val), [])
}

export function firstOrNull<T>(array: T[], check: (item: T) => boolean): T | null {
	for (const item of array)
		if (check(item))
			return item
	return null
}

export function removeNullItems<T>(array: Array<T | null>): T[] {
	return array.filter(item => item !== null) as T[]
}

export function toItemMap<T extends IItem>(array: T[]): Map<T["id"], T> {
	return new Map(array.map(i => [i.id, i]))
}
