import Configuration from "@/managers/system/configuration"
import {watch, when} from "@/store/store"
import User from "@/managers/user/user"
import Tracking from "@/managers/session/logging/tracking"

declare global {
	interface Window {
		dataLayer: any[]
	}
}

class GlobalSiteTag {
	private isInitialized = false

	constructor() {
		this.initialize()

		when(() => User.canTrack || Tracking.canTrackUnauthenticated)
			.then(() => this.updateConsent(false, true))

		watch(
			() => User.current,
			user => this.config({user_id: user?.id}),
			{immediate: true})
	}

	public logEvent(name: string, data: {[key: string]: string}): void {
		this.gtag("event", name, data)
	}

	private initialize(): void {
		if (this.isInitialized)
			return
		this.isInitialized = true

		const script = document.createElement("script")
		script.async = true
		script.src=`https://www.googletagmanager.com/gtag/js?id=${Configuration.connections.globalSiteTagId}`
		document.head.insertBefore(script, document.head.firstChild)

		window.dataLayer = window.dataLayer || []

		this.updateConsent(true, false)

		this.gtag("js", new Date())
		this.config({send_page_view: true})
	}

	private config(data: object): void {
		this.gtag("config", Configuration.connections.globalSiteTagId, data)
	}

	private gtag(...args: any[]): void {
		window.dataLayer.push(arguments)
	}

	private updateConsent(setDefault: boolean, granted: boolean): void {
		this.gtag("consent", setDefault ? "default" : "update", this.createConsentOptions(granted))
	}

	private createConsentOptions(granted: boolean): object {
		return {
			"ad_storage": granted ? "granted" : "denied",
			"ad_user_data": granted ? "granted" : "denied",
			"ad_personalization": granted ? "granted" : "denied",
			"analytics_storage": granted ? "granted" : "denied"
		}
	}
}

export default new GlobalSiteTag()
