import Vue from "vue"
import CalendarType from "@/managers/data/preferences/calendarType"
import {localStorage} from "@/managers/system/fallbackStorage"
import Notifications from "@/managers/session/notifications"
import type { DealState } from "@chaosinsight/postoffice-portalclient"

class Preferences {
	private static readonly storageKey = "preferences"
	private state = Vue.observable({calendarType: CalendarType.list, dealsFilter: {state: null, query: ""} as DealFilterPreference})

	public get calendarType(): CalendarType {
		return this.state.calendarType
	}
	public set calendarType(value: CalendarType) {
		this.state.calendarType = value
		this.save()
	}

	public get dealsFilter(): DealFilterPreference {
		return this.state.dealsFilter
	}
	public set dealsFilter(value: DealFilterPreference) {
		this.state.dealsFilter = value
		this.save()
	}

	constructor() {
		try {
			const data = localStorage.getItem(Preferences.storageKey)

			if (data !== null) {
				const parsedData = JSON.parse(data)

				if (parsedData.calendarType)
					this.state.calendarType = parsedData.calendarType
				if (parsedData.dealsFilter)
					this.state.dealsFilter = parsedData.dealsFilter
			}
		} catch (error) {
			Notifications.warning("Failed to load preferences", error as Error)
		}
	}

	private save(): void {
		localStorage.setItem(Preferences.storageKey, JSON.stringify(this.state))
	}
}

export type DealFilterPreference = {state: DealState | null, query: string}
export default new Preferences()
