import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store/store"
import IAuthenticationMethod, {AuthenticationMethodState} from "./iauthenticationMethod"

@Module({dynamic: true, namespaced: true, name: "authentication/savedLogin", store})
export class SavedLogin extends VuexModule implements IAuthenticationMethod {
	public currentState = AuthenticationMethodState.Uninitialized

	public get isLoggingIn(): boolean {
		return this.currentState === AuthenticationMethodState.LoggingIn
	}

	public get canLogin(): boolean {
		return this.currentState === AuthenticationMethodState.CanLogin
	}

	@Mutation
	public setState(value: AuthenticationMethodState): void {
		this.currentState = value
	}
}

export default getModule(SavedLogin)
