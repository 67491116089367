export enum AuthenticationMethodState {
	Uninitialized,
	CanLogin,
	CannotLogin,
	LoggingIn,
}

export default interface IAuthenticationMethod {
	currentState: AuthenticationMethodState
	isLoggingIn: boolean
	canLogin: boolean
}
