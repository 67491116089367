const minuteMs = 60_000
const hourMs = 60 * minuteMs
export default {
	minimumDate: -62135596800000,
	system: {
		version: process.env.VUE_APP_VERSION!,
		updateThreshold: 3,
		patchNotesDismissDuration: 12 * hourMs,
		nowUpdateInterval: 10_000,
		isUnloadedCheckDelay: 300
	},
	general: {
		queryDelay: 400,
		currencies: ["DKK", "EUR", "GBP", "NOK", "SEK", "USD"],
	},
	connections: {
		portalPath: process.env.VUE_APP_CONNECTIONS_PORTAL_PATH!,
		facebookAppId: process.env.VUE_APP_CONNECTIONS_FACEBOOK_APP_ID!,
		intercomAppId: process.env.VUE_APP_CONNECTIONS_INTERCOM_APP_ID!,
		globalSiteTagId: process.env.VUE_APP_CONNECTIONS_GLOBAL_SITE_TAG_ID!,
		errorLogPath: "https://errorlog.postofficesocial.com",
		errorLogEnvironment: process.env.VUE_APP_ERROR_LOG_ENVIRONMENT!,
		facebookSdkTimeout: 10_000
	},
	localization: {
		default: process.env.VUE_APP_LOCALIZATION_DEFAULT!,
		fallback: "en",
		names: {"en": "English","da": "Dansk"}
	},
	documents: {
		termsVersion: "TermsOfUse:v1"
	},
	navigation: {
		navigationListMaxItems: 10
	},
	files: {
		statusUpdateInterval: 10_000
	},
	signUp: {
		redirectKey: "signUpRedirect"
	},
	deals: {
		orders: {
			anonymizedName: "Anonymized",
			list: {
				updateInterval: 10_000
			}
		},
		list: {
			pageSize: 10,
			updateInterval: 20_000
		},
		blackListedPlaceholders: ["ProductDescription"]
	},
	live: {
		goLiveUrl: "https://www.facebook.com/live/producer/?target_id=$id"
	},
	content: {
		pageSize: 20
	},
	calendar: {
		loadChunkWeeks: 2,
		loadInitialWeek: -1,
		loadMinimumInterval: 100,
		limitPastWeeks: 100,
		limitFutureWeeks: 100,
		updateInterval: 30_000,
		errorDelay: 1_000,
		publishSuccessCloseDelay: 2_500,
		expireInterval: 6 * hourMs
	},
	post: {
		defaultPublishTime: 12,
		dateMinuteInterval: 5,
		pastDateBuffer: 5 * minuteMs,
		postingSoonLimit: minuteMs,
		invalidLinks: [/^https:\/\/(?:www.)?facebook.com\/events/],
		deals: {
			defaultSaleToDurationDays: 4
		},
		live: {
			defaultMatchEndDuration: hourMs,
			matchEndDurationOptions: [
				5 * minuteMs,
				15 * minuteMs,
				30 * minuteMs,
				hourMs,
				2 * hourMs,
				4 * hourMs,
				6 * hourMs,
				10 * hourMs],
		}
	},
	imageEditor: {
		path: "/imageEditor/index.html",
		maxNumberOfColorsAndFonts: 6
	},
	insights: {
		pageSize: 20,
		standardPeriods: [7, 28, 84],
		maxPeriod: 93
	}
}
