import Pages from "@/managers/facebook/pages"
import InstagramBusinessAccount from "@/managers/data/instagram/InstagramBusinessAccount"

export default class FacebookPage {
	public id: string
	public name: string
	public city: string | null
	public street: string | null
	public instagramAccount: InstagramBusinessAccount | null

	public get subName(): string {
		return [
			this.city,
			this.street
		].filter(i => i !== null).join(", ")
	}

	public get hasSubName(): boolean {
		return this.subName !== ""
	}

	public get sortName(): string {
		return this.name + this.subName
	}

	public get imageUrl(): string {
		return Pages.getImage(this.id)
	}

	public get hasInstagram(): boolean {
		return this.instagramAccount !== null
	}

	public get url(): string {
		return FacebookPage.getUrlFromId(this.id)
	}

	constructor(id: string, name: string, city: string | null, street: string | null, instagramAccount: InstagramBusinessAccount | null) {
		this.id = id
		this.name = name
		this.city = city
		this.street = street
		this.instagramAccount = instagramAccount
	}

	public static fromSdk(data: IFacebookPage): FacebookPage {
		return new FacebookPage(
			data.id,
			data.name,
			data.location?.city ?? null,
			data.location?.street ?? null,
			data.instagram_business_account ? InstagramBusinessAccount.fromSdk(data.instagram_business_account) : null)
	}

	public static getUrlFromId(id: string): string {
		return `https://www.facebook.com/${id}`
	}
}
