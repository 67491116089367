import Vue from "vue"
import Navigation from "@/managers/session/navigation"

class Loading {
	private readonly state: {isLoading: boolean}

	public get isLoading(): boolean {
		return this.state.isLoading
	}

	constructor() {
		this.state = Vue.observable({isLoading: false})
	}

	public while(task: Promise<unknown>): void {
		this.state.isLoading = true

		task.finally(() => this.state.isLoading =false)
	}

	public goto(task: Promise<string | null>): void {
		this.state.isLoading = true
		task
			.then(path => {
				if (path !== null)
					Navigation.to(path)
			})
			.finally(() => this.state.isLoading = false)
	}
}

export default new Loading()
