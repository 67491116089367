<template lang="pug">
	v-overlay(:value="isOpen" :opacity="isFullyVisible ? 1 : 0" z-index="1000")
		Loading(v-if="isFullyVisible" size="50" :bottomMargin="false")
</template>
<script lang="ts">
	import { Component, Vue} from "vue-property-decorator"
	import LoadingManager from "@/managers/session/loading"
	import Notifications from "@/managers/session/notifications"
	import Loading from "@/components/output/loading.vue"

	@Component({components: {Loading}})
	export default class LoadingOverlay extends Vue {
		private isFullyVisible = false

		private get isOpen(): boolean {
			if (LoadingManager.isLoading) {
				this.isFullyVisible = false
				setTimeout(() => (this.isFullyVisible = true), 500)
				return true
			} else
				return false
		}

		private set isOpen(value: boolean) {
			Notifications.warning("Loading dialog can not be forced closed")
		}
	}
</script>
