import Vue, {WatchOptions} from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const store = new Vuex.Store({
	strict: process.env.NODE_ENV !== "production"
})

export async function when(condition: () => boolean): Promise<void> {
	if (condition())
		return

	return new Promise((resolve, reject) => {
		const unwatch = store.watch(condition, () => {
			unwatch()
			resolve()
		})
	})
}

export function nextTick(): Promise<void> {
	return Vue.nextTick()
}

export function watch<T>(getter: () => T, cb: (value: T, oldValue: T) => void, options?: WatchOptions): () => void {
	return store.watch(getter, cb, options)
}

export default store
