import type Router from "vue-router"
import Routing from "@/store/session/routing"

export default function(router: Router): void {
	router.beforeEach((to, from, next) => {
		Routing.setRoutingStatus("routing")
		next()
	})

	router.afterEach((to, from) => {
		Routing.routeComplete()
	})
}
