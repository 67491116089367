import type {IPrivateServiceConnection} from "@chaosinsight/postoffice-portalclient"
import {ServiceConnectionType} from "@chaosinsight/postoffice-portalclient"
import ServiceConnection from "@/managers/data/serviceConnection/serviceConnection"

export default class PrivateServiceConnection extends ServiceConnection {
	public locationId: number
	public errorMessage: string | null

	constructor(id: string, typeId: ServiceConnectionType, serviceId: string, mustRenew: boolean, createdOn: Date, modifiedOn: Date, locationId: number, errorMessage: string | null) {
		super(id, typeId, serviceId, mustRenew, createdOn, modifiedOn)
		this.locationId = locationId
		this.errorMessage = errorMessage
	}

	public static fromApi(data: IPrivateServiceConnection): PrivateServiceConnection {
		return new PrivateServiceConnection(
			data.Id,
			data.TypeId,
			data.ServiceId,
			data.MustRenew,
			new Date(data.CreatedOn),
			new Date(data.ModifiedOn),
			data.LocationId,
			data.ErrorMessage)
	}
}

export {ServiceConnectionType}
