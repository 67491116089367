import Intercom, {boot, show, shutdown, update} from "@intercom/messenger-js-sdk"
import Locations from "@/managers/location/locations"
import Organizations, {OrganizationType} from "@/managers/organization/organizations"
import User from "@/managers/user/user"
import configuration from "@/managers/system/configuration"
import Install from "@/managers/system/install"
import {isPWA} from "@/managers/system/capabilities"
import ThrottledAction from "@/utility/actions/throttledAction"
import Services from "@/managers/location/services"
import {firstOrNull} from "@/utility/structure/array"
import {watch} from "@/store/store"
import SignUpModules from "@/managers/session/signUpModules"
import Navigation from "@/managers/session/navigation"

class Support {
	private isBooted = false
	private updateAction: ThrottledAction

	constructor() {
		this.updateAction = new ThrottledAction(() => this.update(), 1000)
		Intercom({hide_default_launcher: true, app_id: configuration.connections.intercomAppId})

		watch(
			() => User.current,
			user => {
				if (user !== null)
					this.updateAction.delay()
				else {
					this.isBooted = false
					shutdown()
				}
			}
		)

		watch(
			() => [Organizations.isReady && Locations.isReady, Services.isReady, Organizations.main, Organizations?.main?.hasUpdatedPermission, Locations.main, SignUpModules.modules, Navigation.isUserBusy],
			values => {
				if (!User.isReady || !values[0])
					return

				this.updateAction.delay()
			}
		)
	}

	public show(): void {
		show()
	}

	private update(): void {
		const user = User.current!
		const location = Locations.main
		const organization = Organizations.main
		const facebook = Services.isReady ? firstOrNull(Services.list!, s => s.isFacebook) : null
		const instagram = Services.isReady ? firstOrNull(Services.list!, s => s.isFacebook) : null

		const argument = {
			hide_default_launcher: true,
			app_id: configuration.connections.intercomAppId,
			email: user.emailAddress,
			user_id: user.id,
			name: user.name,
			userLocationsCount: Locations.count ?? 0,
			isUserBusy: Navigation.isUserBusy,
			currentPlatformType: isPWA ? "PWA" : "Web",
			currentFrontendVersion: Install.version,
			currentFrontendIsUpdatable: Install.isUpdateReady,
			signUpModules: SignUpModules.modules.join(","),
			currentOrganizationId: organization?.id ?? null,
			currentOrganizationName: organization?.name ?? null,
			currentOrganizationIsSingleStore:  organization?.isSingleStore ?? false,
			currentOrganizationIsBrand: organization?.isBrand ?? false,
			currentOrganizationIsAdmin: organization?.canManage ?? false,
			currentLocationId: location?.id ?? null,
			currentLocationName: location?.name ?? null,
			currentLocationHasDeals: location?.isDealsEnabled ?? null,
			currentLocationHasLiveDeals: location?.isLiveDealsEnabled ?? null,
			currentLocationMembersCount: location?.userCount ?? null,
			currentLocationFollowersCount: location?.followers ?? null,
			currentLocationFacebookAccount: facebook?.url ?? null,
			currentLocationFacebookIsWorking: facebook?.connections.some(c => !c.mustRenew) ?? false,
			currentLocationInstagramAccount: instagram?.url ?? null,
			currentLocationInstagramIsWorking: instagram?.connections.some(c => !c.mustRenew) ?? false,
		}

		if (this.isBooted)
			update(argument)
		else
			boot(argument)

		this.isBooted = true
	}
}

export default new Support()
