import State from "@/store/user/serviceConnections"
import Portal from "@/managers/portal"
import List from "@/managers/base/list"
import Authentication from "@/managers/authentication/authentication"
import FacebookAccounts from "@/managers/facebook/accounts"
import FacebookAuthentication from "@/managers/facebook/authentication"
import Permissions from "@/managers/facebook/permissions"
import PrivateServiceConnection, {ServiceConnectionType} from "@/managers/data/serviceConnection/privateServiceConnection"
import {watch} from "@/store/store"
import type FacebookAccount from "@/managers/data/facebook/facebookAccount"
import {firstOrNull} from "@/utility/structure/array"
import Notifications from "@/managers/session/notifications"
import Services from "@/managers/location/services"
import Tracking from "@/managers/session/logging/tracking"

class ServiceConnections extends List<PrivateServiceConnection, true> {
	private automaticallyRenewingPromise: Promise<boolean> | null = null
	private removePromise: Promise<void> | null = null

	public get hasAutomaticallyRenewed(): boolean {
		return this.state.hasAutomaticallyRenewed
	}

	public get isAutomaticallyRenewing(): boolean {
		return this.state.isAutomaticallyRenewing
	}

	public get isRemoving(): boolean {
		return this.state.isRemoving
	}

	public get canAutomaticallyRenewFacebook(): boolean | null {
		return this.canAutomaticallyRenew !== null
			? this.canAutomaticallyRenew && Permissions.hasAllPagePermissions
			: null
	}

	public get canAutomaticallyRenewInstagram(): boolean | null {
		return this.canAutomaticallyRenew !== null
			? this.canAutomaticallyRenew && Permissions.hasAllInstagramPermissions
			: null
	}

	public get mustRenewAny(): boolean {
		return this.isReady && this.list!.some(connection => connection.mustRenew)
	}

	public get mustRenewList(): PrivateServiceConnection[] {
		return this.isReady
			? Permissions.hasPermissionsChanged
				? this.list!
				: this.list!.filter(connection => connection.mustRenew)
			: []
	}

	protected get state(): typeof State {
		return State
	}

	protected get dependent(): true | null {
		return Authentication.isAuthenticated || null
	}

	private get canAutomaticallyRenew(): boolean | null {
		if (FacebookAuthentication.hasModuleFailed)
			return false

		if (!FacebookAuthentication.hasCheckedLogin || FacebookAuthentication.isLoggingIn)
			return null

		if (!FacebookAuthentication.isLoggedIn)
			return false

		if (!Permissions.hasCheckedPermissions)
			return null

		return true
	}

	constructor() {
		super("user service connections")

		watch(
			() => this.isReady && Permissions.hasAllPagePermissions && FacebookAccounts.isReady,
			isReady => {
				if (!isReady || this.hasAutomaticallyRenewed)
					return

				this.attemptAutomaticRenewal()
					.catch(reason => Notifications.warning("Failed to automatically renew service connections", reason))
			}, {immediate: true})
	}

	public attemptAutomaticRenewal(): Promise<boolean> {
		if (this.automaticallyRenewingPromise === null) {
			if (this.isRemoving)
				return Promise.reject(new Error("Can't renew connections while removing"))

			const mustRenew = this.mustRenewList.filter(c => c.isFacebook || Permissions.hasAllInstagramPermissions)
			const accounts = FacebookAccounts.list ?? []

			Tracking.event("ServiceConnections", "Must renew", mustRenew.length > 0
				? accounts.length > 0
					? "Can attempt"
					: "Can't attempt"
				: "No need", true)
			if (mustRenew.length > 0 && accounts.length > 0)
				this.automaticallyRenewingPromise = this.renew(mustRenew, accounts)
					.then(() => this.mustRenewList.length === 0)
					.finally(() => this.automaticallyRenewingPromise = null)
			else {
				this.automaticDone()
				return Promise.resolve(true)
			}
		}

		return this.automaticallyRenewingPromise
	}

	public removeMustRenewConnections(): Promise<void> {
		if (this.removePromise === null) {
			if (this.mustRenewList.length === 0)
				return Promise.resolve()

			if (this.isAutomaticallyRenewing)
				return Promise.reject(new Error("Can't remove connections while automatically renewing"))

			this.state.setIsRemoving(true)

			this.removePromise = Promise.all(this.mustRenewList.map(connection => this.removeConnection(connection)))
				.then(() => {})
				.finally(() => {
					this.state.setIsRemoving(false)
					this.removePromise = null
				})
		}

		return this.removePromise
	}

	public getFromLocation(id: number, type: ServiceConnectionType): PrivateServiceConnection | null {
		if (!this.isReady)
			return null

		return firstOrNull(this.list!, connection => connection.locationId === id && connection.type === type)
	}

	protected apiLoad(dependent: boolean): Promise<PrivateServiceConnection[]> {
		return Portal.call.serviceConnections.get().response
			.then(connections => connections.map(connection => PrivateServiceConnection.fromApi(connection)))
	}

	protected reset(): void {
		super.reset()
		this.state.setHasAutomaticallyRenewed(false)
		this.state.setIsAutomaticallyRenewing(false)
	}

	private automaticDone(): void {
		this.state.setHasAutomaticallyRenewed(true)
	}

	private renew(connections: PrivateServiceConnection[], accounts: FacebookAccount[]): Promise<PrivateServiceConnection[]> {
		this.state.setIsAutomaticallyRenewing(true)
		const calls: Array<Promise<PrivateServiceConnection>> = []

		for (const connection of connections) {
			const account = firstOrNull(accounts, a => a.id === connection.serviceId)

			if (account !== null) {
				if (account.hasRequiredTasks)
					calls.push(this.renewConnection(connection, account))
				else
					Notifications.warning("User no longer has rights to service: " + connection.serviceId)
			} else
				Notifications.warning("User no longer has access to service: " + connection.serviceId)
		}
		return Promise.all(calls)
			.finally(() => {
				this.state.setIsAutomaticallyRenewing(false)
				this.automaticDone()

				Tracking.event("ServiceConnections", "Automatic renew", this.mustRenewAny
					? connections.length === this.mustRenewList.length
						? "Partial"
						: "Failed"
					: "Success", true)
			})
	}

	private async removeConnection(connection: PrivateServiceConnection): Promise<void> {
		return Services.deleteConnection(connection.locationId, connection)
			.then(() => {
				const index = this.state.list!.findIndex(c => c.locationId === connection.locationId)
				if (index !== -1)
					this.state.removeConnection(index)
			})
	}

	private async renewConnection(connection: PrivateServiceConnection, account: FacebookAccount): Promise<PrivateServiceConnection> {
		await Services.addToLocation(connection.serviceId, ServiceConnectionType.facebook, connection.locationId)
			.then(
				() => {
					if (this.state.isReady) {
						const index = this.state.list!.findIndex(c => c.locationId === connection.locationId)
						if (index !== -1)
							this.state.setConnectionRenewed(index)
					}
					Notifications.debug(`Automatically updated service connection for ${account.name}`)
				},
				reason => Notifications.warning(`Failed to update service connection for ${account.name}`, reason))
		return connection
	}
}

export {PrivateServiceConnection}
export default new ServiceConnections()
