import I18n from "@/plugins/i18n"
import User from "@/managers/user/user"
import {watch} from "@/store/store"
import Notifications from "@/managers/session/notifications"
import Configuration from "@/managers/system/configuration"
import type {TranslateResult, Values} from "vue-i18n"

class Localization {
	public get current(): string {
		return I18n.locale
	}
	public set current(value: string) {
		if (User.isReady)
			User.setLanguage(value)
				.catch(reason => Notifications.error("Failed to change language", reason))
		else
			I18n.locale = value
	}

	public get decimalPoint(): string {
		return this.current === "da" ? "," : "."
	}

	public get languages(): string[] {
		return I18n.availableLocales
	}

	public get languageNames(): {[key: string]: string} {
		return Configuration.localization.names
	}

	constructor() {
		watch(() => User.current?.language ?? null, language => {
			if (language !== null)
				I18n.locale = language
		})

		watch(
			() => I18n.locale,
			language => {
				try {
					window.document.documentElement.setAttribute("lang", language)
					window.document.documentElement.setAttribute("xml:lang", language)
					document.head.querySelector("meta[http-equiv=\"Content-Language\"]")!.setAttribute("content", language)
				} catch (error: any) {
					Notifications.warning("Failed to update language attributes", error)
				}
			},
			{immediate: true}
		)
	}

	public exists(path: string): boolean {
		return I18n.te(path)
	}

	public translate(path: string, values?: Values): TranslateResult {
		return I18n.t(path, values)
	}

	public getNumber(value: number, format: string, locale?: string): TranslateResult {
		return I18n.n(value, format, locale ?? this.current)
	}

	public getCurrency(value: number, currency: string, useSymbol: boolean): TranslateResult {
		return I18n.n(value, {
			key: "currency", currency,
			currencyDisplay: useSymbol ? "narrowSymbol" : "code"})
	}

	public getCurrencyFormat(currency: string, useSymbol: boolean) : Intl.NumberFormat {
		const currencyFormat = I18n.getNumberFormat(I18n.locale)["currency"]
		currencyFormat.currency = currency
		currencyFormat.currencyDisplay = useSymbol ? "narrowSymbol" : "code"

		return Intl.NumberFormat(this.current, currencyFormat)
	}
}

export {TranslateResult}
export default new Localization()
