import State from "@/store/facebook/authentication"
import Authentication from "@/managers/facebook/authentication"

class Permissions {
	private readonly pagePermissions = ["pages_show_list", "pages_manage_posts", "pages_read_engagement", "pages_read_user_content", "pages_manage_metadata", "pages_manage_engagement", "pages_messaging", "read_insights", "business_management"]
	private readonly instagramPermissions = ["pages_show_list", "pages_read_engagement", "instagram_basic", "instagram_content_publish", "instagram_manage_insights"]

	public get allPermissions(): string[] {
		return Array.from(new Set(this.pagePermissions.concat(this.instagramPermissions)))
	}

	public get hasCheckedPermissions(): boolean {
		return State.hasCheckedPermissions
	}

	public get hasPermissionsChanged(): boolean {
		return State.hasPermissionsChanged
	}

	public get hasAllPagePermissions(): boolean {
		return this.pagePermissions.every(p => State.grantedPermissions.indexOf(p) !== -1)
	}

	public get hasAllInstagramPermissions(): boolean {
		return this.instagramPermissions.every(p => State.grantedPermissions.indexOf(p) !== -1)
	}

	public requestPagePermissions(): Promise<boolean> {
		return Authentication.requestPermissions(this.pagePermissions)
	}

	public requestInstagramPermissions(): Promise<boolean> {
		return Authentication.requestPermissions(this.instagramPermissions)
	}

	public requestAllPermissions(): Promise<boolean> {
		return Authentication.requestPermissions(this.allPermissions)
	}
}

export default new Permissions()
