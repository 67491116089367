<template lang="pug">
	v-container.fill-height.justify-center.loading
		v-progress-circular(:size="size" :width="2" :color="color" indeterminate :class="{'mb-10': bottomMargin}")
</template>
<script lang="ts">
	import { Component, Prop, Vue } from "vue-property-decorator"

	@Component({components: {}})
	export default class Loading extends Vue {
		@Prop({default: 21})
		public readonly size!: number
		@Prop({default: true})
		public readonly bottomMargin!: boolean
		@Prop({default: "#9FA3B0"})
		public readonly color!: string
	}
</script>
