const instagramUrlMask = / /g

export default class InstagramBusinessAccount {
	public id: string
	public username: string

	public get url(): string {
		return InstagramBusinessAccount.getUrlFromUsername(this.username)
	}

	constructor(id: string, username: string) {
		this.id = id
		this.username = username
	}

	public static fromSdk(data: IInstagramBusinessAccount): InstagramBusinessAccount {
		return new InstagramBusinessAccount(
			data.id,
			data.username)
	}

	public static getUrlFromUsername(username: string): string {
		return `https://www.instagram.com/${username.replace(instagramUrlMask, "").toLocaleLowerCase()}`
	}
}
