import { getModule, Action, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "../store"
import type { Notification } from "@/managers/data/session/notification"

@Module({dynamic: true, namespaced: true, name: "session/notifications", store})
export class Notifications extends VuexModule {
	public list: Notification[] = []

	@Mutation
	public add(notification: Notification): void {
		this.list.push(notification)
	}
}


export default getModule(Notifications)
