import type {RawLocation, Route} from "vue-router"
import Router from "vue-router"
import Routing from "@/store/session/routing"
import Notifications from "@/managers/session/notifications"
import Authentication from "@/managers/authentication/authentication"

async function checkRouteAuthentication(route: Route): Promise<RawLocation | null> {
	const requiresAuthenticated = route.matched.some(record => record.meta.requiresAuthenticated)
	const requiresGuest = route.matched.some(record => record.meta.requiresGuest)

	if (!requiresAuthenticated && !requiresGuest)
		return null

	const isAuthenticated = await Authentication.checkLogin()

	if (requiresGuest && isAuthenticated) {
		if (route.query.hasOwnProperty("redirect")) {
			Notifications.debug("Rerouting to redirect parameter, current route requires guest")
			return route.query.redirect as string
		}
		Notifications.debug("Rerouting to organization selection, current route requires guest")
		return { path: "/" }
	} else if (requiresAuthenticated && !isAuthenticated) {
		Notifications.debug("Rerouting to login, current route requires authentication")
		return {
			name: "login",
			query: {
				redirect: route.fullPath
			}
		}
	} else
		return null
}

export default function(router: Router): void {
	router.beforeEach((to, from, next) => {
		Routing.setRoutingStatus("checkingLogin")
		checkRouteAuthentication(to)
			.then(location => next(location ?? undefined), reason => {
				Notifications.error("Failed to check authentication for route", reason)
				next()
			})
			.catch(reason => {
				if (!Router.isNavigationFailure(reason, Router.NavigationFailureType.redirected))
					throw reason
			})
	})

	Authentication.watchIsAuthenticated(isAuthenticated => {
		checkRouteAuthentication(router.currentRoute)
			.then(location => {
				if (location !== null)
					return router.push(location)
			})
			.catch(reason => {
				if (!Router.isNavigationFailure(reason, Router.NavigationFailureType.redirected))
					throw reason
			})
	})
}
