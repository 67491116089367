export class Notification {
	public readonly type: NotificationType
	public readonly message: string
	public readonly title: string | null

	constructor(type: NotificationType, message: string, title: string | null = null) {
		this.type = type
		this.message = message
		this.title = title
	}

	public static information(message: string): Notification {
		return new Notification(NotificationType.information, message)
	}

	public static error(message: string, title: string | null = null): Notification {
		return new Notification(NotificationType.error, message, title)
	}

	public static warning(message: string): Notification {
		return new Notification(NotificationType.warning, message)
	}

	public static debug(message: string): Notification {
		return new Notification(NotificationType.debug, message)
	}
}

export enum NotificationType {
	information,
	error,
	warning,
	debug
}
