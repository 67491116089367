<template lang="pug">
	v-app#app(:class="{preload}")
		v-main
			OfflineBanner
			Loading(v-if="isRouting")
			router-view(v-if="isRouteValid" v-show="!isRouting")
		LoadingOverlay
		UpdateDialog
		Notifications
		CookieConsentDialog
		v-chip(x-small).pa-1.version {{version}}
</template>
<script lang="ts">
	import { Component, Vue } from "vue-property-decorator"
	import Install from "@/managers/system/install"
	import Authentication from "@/managers/authentication/authentication"
	import NotificationsManager from "@/managers/session/notifications"
	import Notifications from "@/components/notifications.vue"
	import Loading from "@/components/output/loading.vue"
	import UpdateDialog from "@/components/dialogs/updateDialog.vue"
	import OfflineBanner from "@/components/offlineBanner.vue"
	import LoadingOverlay from "@/components/dialogs/loadingOverlay.vue"
	import "@/managers/session/logging/tracking"
	import "@/managers/session/localization"
	import "@/managers/user/serviceConnections"
	import Navigation from "@/managers/session/navigation"
	import CookieConsentDialog from "@/components/dialogs/cookieConsentDialog.vue"

	@Component({components: {CookieConsentDialog, LoadingOverlay, Notifications, OfflineBanner, Loading, UpdateDialog}})
	export default class App extends Vue {
		private preload = true

		private get isRouting(): boolean {
			return Navigation.isRouting
		}

		private get isRouteValid(): boolean {
			return this.$route && (Authentication.isAuthenticated || !this.$route.matched.some(r => r.meta.requiresAuthenticated))
		}

		private get version(): string {
			return Install.version
		}

		private get fullI18nPath(): string | null {
			const hierarchy: string[] = []

			for (const matched of this.$route.matched) {
				if (matched.meta.i18n === undefined) {
					NotificationsManager.warning("I18n key is not defined on matched view: " + matched.path)
					continue
				}

				hierarchy.push(matched.meta.i18n)
			}

			return hierarchy.length > 0 ? `views.${hierarchy.join(".")}.` : null
		}

		public created(): void {
			this.$watch(() => [
					this.getRoutingStatus(Navigation.routingStatus),
					this.getTitle(this.fullI18nPath),
					this.$t("siteTitle.postFix").toString()
				].filter(value => value !== null).join(" - "),
				title => document.title = title,
				{immediate: true})
		}

		public mounted() {
			setTimeout(() => this.preload = false, 1000)
		}

		private getRoutingStatus(value: string | null): string | null {
			if (value === null)
				return null

			if (this.$te(`siteTitle.routingStatus.${value}`))
				return this.$t(`siteTitle.routingStatus.${value}`).toString()

			NotificationsManager.warning("Could not find routing status: " + value)

			return value
		}

		private getTitle(value: string | null): string | null {
			if (value === null)
				return null

			if (this.$te(`${value}siteTitle`))
				return this.$t(`${value}siteTitle`).toString()

			NotificationsManager.warning("Could not find site title: " + value)

			return null
		}
	}
</script>
<style lang="sass" scoped>
	#app
		background-color: initial
		&.preload *
			transition: none !important
		.v-main
			transition: padding-left .2s $ease-in-out
		.version
			position: fixed
			right: 0
			bottom: 0
			z-index: 1000
			opacity: 0
			&:hover
				opacity: .5
</style>
