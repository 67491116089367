<template lang="pug">
	v-btn(
		v-on="$listeners"
		:disabled="disabled"
		:min-width="$vuetify.breakpoint.smAndUp ? 140 : null"
		:loading="loading"
		:outlined="secondary"
		:color="secondary ? 'lightgrey' : undefined"
		:type="submit ? 'submit' : undefined"
		depressed height="48"
	).bigButton.flex-grow-1.flex-sm-grow-0.px-6.px-md-9
		.black--text
			slot
</template>
<script lang="ts">
	import { Component, Prop, Vue } from "vue-property-decorator"

	@Component({components: {}})
	export default class TextButton extends Vue {
		@Prop({type: Boolean})
		public readonly disabled!: boolean
		@Prop({type: Boolean})
		public readonly loading!: boolean
		@Prop({type: Boolean})
		public readonly secondary!: boolean
		@Prop({type: Boolean})
		public readonly submit!: boolean
	}
</script>
