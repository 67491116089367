<template lang="pug">
	v-snackbar(:value="isVisible" min-height="97" :color="typeName" :timeout="-1")
		.d-flex
			PoIcon(v-if="icon" :icon="icon" size="49" color="white" no-offset).mr--7
			.d-flex.flex-column.justify-start
				h4.font-weight-bold {{title | capitalize}}
				p.text-body-1.mb-2 {{message}}
		template(#action)
			v-btn(@click="close" icon).align-self-start
				PoIcon(icon="close" size="16")
</template>
<script lang="ts">
	import {Component, Vue} from "vue-property-decorator"
	import NotificationsManager, {Notification, NotificationType} from "@/managers/session/notifications"

	@Component({components: {}})
	export default class Notifications extends Vue {
		private index = 0
		private isClosed = false

		private get current(): Notification | null {
			return this.isAvailable ? NotificationsManager.list[this.index] : null
		}

		private get message(): string {
			return this.isAvailable ? this.current!.message : ""
		}

		private get type(): NotificationType {
			return this.isAvailable ? this.current!.type : NotificationType.debug
		}

		private get isAvailable(): boolean {
			return NotificationsManager.list.length - 1 >= this.index
		}

		private get isVisible(): boolean {
			return !this.isClosed && this.isAvailable && Notifications.isVisibleType(this.type)
		}

		private get icon(): string | null {
			return this.isVisible ? `snack-${this.typeName}` : null
		}

		private get typeName(): string {
			return NotificationType[this.type]
		}

		private get title(): string {
			return NotificationsManager.list?.[this.index]?.title ?? this.typeName
		}

		public created(): void {
			this.$watch(() => this.current, current => {
				if (current !== null && !Notifications.isVisibleType(current.type))
					this.showNext()
			})
		}

		private close(): void {
			this.isClosed = true
			Vue.nextTick(() => this.showNext())
		}

		private showNext(): void {
			this.isClosed = false
			this.index++
		}

		private static isVisibleType(type: NotificationType): boolean {
			return type === NotificationType.information || type === NotificationType.error
		}
	}
</script>
<style lang="sass" scoped>
	::v-deep .v-snack__wrapper
		align-items: flex-start
		.v-snack__action
			align-self: flex-start
			margin: 5px 5px 0 0
		h4
			margin-bottom: 3px
			line-height: +rem(22)
		p
			line-height: +rem(18)
	.mr--7
		margin-right: 14px
</style>
