import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store/store"
import UsernamePassword from "./usernamePassword"
import FacebookPortal from "./facebookPortal"
import SavedLogin from "./savedLogin"

@Module({dynamic: true, namespaced: true, name: "authentication", store})
class Authentication extends VuexModule {
	public isAuthenticated = false
	public isCheckingLogin = false

	public get isLoggingIn(): boolean {
		return UsernamePassword.isLoggingIn || FacebookPortal.isLoggingIn || SavedLogin.isLoggingIn
	}

	@Mutation
	public setIsAuthenticated(value: boolean): void {
		this.isAuthenticated = value
	}

	@Mutation
	public setIsCheckingLogin(value: boolean): void {
		this.isCheckingLogin = value
	}
}

export default getModule(Authentication)
