import type Router from "vue-router"
import Routing from "@/store/session/routing"
import Organizations from "@/managers/organization/organizations"
import Notifications from "@/managers/session/notifications"

export default function(router: Router): void {
	router.beforeResolve((to, from, next) => {
		const organizationKey = to.params.hasOwnProperty("organizationKey") ? to.params.organizationKey : null

		if (organizationKey !== null) {
			Routing.setRoutingStatus("checkingOrganization")
			Organizations.getByKeyWhenReady(organizationKey)
				.then(organization => {
					Organizations.main = organization
					next()
				}, reason => {
					Notifications.warning("Failed to find organization: " + reason)
					next({name: "notFound", params: {0: to.fullPath}})
				})
		} else {
			if (Organizations.main !== null && !Organizations.wasMainSetFromLocation) {
				Organizations.main = null
				Notifications.debug("Clearing main organization")
			}
			next()
		}
	})
}
